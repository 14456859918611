import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// components
import Loader from './components/Loader'
import PublicRoute from './components/Custom/CustomRoutes/PublicRoute'
import PrivateRoute from './components/Custom/CustomRoutes/PrivateRoute'

// pages
import Login from './pages/Auth/Login'
import PageNotFound from './pages/PageNotFound'

// layout
import DefaultLayout from './layout/DefaultLayout'

// navigation
import AllRoutes from './navigation'

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  return loading ? (
    <Loader />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<PageNotFound />} />
        <Route element={<PublicRoute />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<DefaultLayout />}>
            {AllRoutes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              )
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
