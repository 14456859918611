import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomNoDataComponent from '../../../components/Custom/CustomNoDataComponent'
import CustomDeleteUpdateModal from '../../../components/Custom/CustomDeleteUpdateModal'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomToolTip from '../../../components/Custom/CustomToolTip'

import { columns } from './columns'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../components/Icons'

const { FaRegEdit, FaRegEye, FiChevronDown, FiTrash } = Icons

const AllTax = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [taxList, settaxList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [isloading, setisloading] = useState(false)
  const [searchText, setSearchText] = useState('')
  // const [totalPages, setTotalPages] = useState(1) //eslint-disable-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const fetchData = async () => {
    try {
      setSearchLoading(true)
      const response = await axiosInterceptorInstance.get(
        `/tax/list?limit=${pageSize}&page=${currentPage}&search=${searchText}`,
      )
      settaxList(response.data.taxList)

      setTotalRecords(response.data.totalRecords)
      // setTotalPages(response.data.totalPages)
      setCurrentPage(response.data.currentPage)
      setLoading(false)
      setSearchLoading(false)
    } catch (error) {
      setLoading(false)
      setSearchLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    setSearchLoading(true)
    fetchData()
  }, [pageSize, currentPage, searchText])

  const handleSearch = async () => {
    fetchData()
  }
  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/tax/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'User deleted successfully',
        type: 'success',
      })
      fetchData()
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
    } catch (error) {
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handlePerPageChange = (newPerPage, page) => {
    setPageSize(newPerPage)
    setCurrentPage(page)
  }

  const handleRowClick = (row) => {
    navigate(`/setting/tax/view/${row._id}`)
  }
  const data = taxList?.map((data) => {
    return {
      ...data,

      action: (
        <div className='flex flex-wrap gap-2 mt-1' role='group'>
          <CustomToolTip label='Edit'>
            <NavLink
              to={`/setting/tax/edit/${data._id}`}
              className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
            >
              <FaRegEdit />
            </NavLink>
          </CustomToolTip>
          <CustomToolTip label='View'>
            <NavLink
              to={`/setting/tax/view/${data._id}`}
              className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
            >
              <FaRegEye />
            </NavLink>
          </CustomToolTip>
          <CustomToolTip label='Delete'>
            <button
              onClick={() => deleteModalhandler(data?._id)}
              className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
            >
              <FiTrash />
            </button>
          </CustomToolTip>
        </div>
      ),
    }
  })

  const noDataComponent = (
    <CustomNoDataComponent
      columns={columns}
      noSearchResultMessage={'Your search did not match any results.'}
      noUserMessage={'There are no tax configurations available.'}
      loading={loading}
      searchText={searchText}
      List={taxList}
      data={data}
    />
  )

  return (
    <CustomLoadingSection isLoading={loading}>
      <Fragment>
        <div className='flex justify-between items-center mb-4'>
          <CustomTitle
            Serchbox={{
              searchText,
              setSearchText,
              handleSearch,
              placeholder: 'Search Tax Configuration',
            }}
            customTitle='Tax Configurations'
            createLink={'/setting/tax/create'}
            showCreateLink={true}
          />
        </div>

        {loading ? (
          <div className='flex items-center justify-center w-full'>
            <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
          </div>
        ) : (
          <Fragment>
            <DataTable
              data={data}
              columns={columns}
              sortIcon={<FiChevronDown size={10} />}
              pagination
              paginationServer
              paginationTotalRows={totalRecords}
              paginationPerPage={pageSize}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 60, 80, 100]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerPageChange}
              highlightOnHover
              noDataComponent={noDataComponent}
              pointerOnHover
              onRowClicked={handleRowClick}
              noHeader
              persistTableHead={true}
              progressPending={searchLoading}
              progressComponent={
                <div className='flex items-center justify-center py-5'>
                  <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
                </div>
              }
            />
          </Fragment>
        )}
        {deleteuserModel && (
          <CustomDeleteUpdateModal
            closeModal={closeModal}
            isloading={isloading}
            onSubmit={submitdeletehandler}
            message={'Are you sure you want to delete this tax configuration?'}
          />
        )}
      </Fragment>
    </CustomLoadingSection>
  )
}

export default AllTax
