import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// custom components
import CustomSearch from '../CustomSearch'

import { Icons } from '../../Icons'

const { MdOutlineCreate, BsThreeDotsVertical, MdOutlineKeyboardBackspace } =
  Icons

const CustomTitle = ({
  Serchbox,
  handleAddClick, //eslint-disable-line no-unused-vars
  backBtn,
  customTitle,
  backBtncount,
  createLink,
  showCreateLink,
}) => {
  let searchText, setSearchText, handleSearch, placeholder
  if (Serchbox) {
    ;({ searchText, setSearchText, handleSearch, placeholder } = Serchbox)
  }

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [heading, setHeading] = useState('')

  useEffect(() => {
    if (location.pathname) {
      const Head = location.pathname.split('/')
      const charactor = Head.some((element) => /[a-zA-Z]/.test(element))
      if (!charactor) {
        setHeading('')
      } else {
        if (Head[1].includes('-')) {
          const slug = Head[1].replace('-', ' ')
          setHeading(slug)
        } else {
          setHeading(Head[1])
        }
      }
    } else {
      setHeading()
    }
  }, [params])

  const handleBackNavigation = () => {
    if (backBtncount) {
      navigate(-backBtncount)
    } else {
      navigate(-1)
    }
  }

  return (
    <Fragment>
      <div className='flex items-center justify-between w-full gap-5 pt-0 mb-5'>
        <div className='title title-gray !w-auto'>
          <h2 className='flex flex-wrap items-center text-2xl capitalize'>
            {customTitle ? customTitle : heading}&nbsp;
            <BsThreeDotsVertical className='text-primary' />
          </h2>
        </div>
        <div className='flex flex-wrap items-center gap-5'>
          {Serchbox ? (
            <CustomSearch
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onClick={handleSearch}
              disabled={!searchText}
              placeholder={placeholder}
            />
          ) : (
            ''
          )}
          <div className='flex flex-wrap items-center gap-5'>
            {showCreateLink && (
              <NavLink
                to={createLink}
                className='flex items-center justify-center gap-1 px-4 py-2 text-sm font-medium transition rounded-full bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
              >
                <MdOutlineCreate className='text-base' /> Create
              </NavLink>
            )}
            {backBtn ? (
              <button
                onClick={handleBackNavigation}
                className='flex items-center justify-center gap-1 px-4 py-2 text-sm font-medium transition rounded-full bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
              >
                <MdOutlineKeyboardBackspace /> <span>Go Back</span>
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

CustomTitle.propTypes = {
  Serchbox: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    setSearchText: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  }),
  handleAddClick: PropTypes.func,
  backBtn: PropTypes.bool,
  customTitle: PropTypes.string,
  backBtncount: PropTypes.number,
  createLink: PropTypes.string,
  showCreateLink: PropTypes.bool,
}

export default CustomTitle
