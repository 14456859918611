import axios from 'axios'
import configManager from '../../config/configManager'

const config = configManager()

const axiosInstance = axios.create({
  baseURL: `${config.API_URL}/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const axiosAuthInstance = async (url, values) => {
  //eslint-disable-next-line no-useless-catch
  try {
    const response = await axiosInstance.post(url, values)
    return response
  } catch (error) {
    throw error
  }
}

export default axiosAuthInstance
