import React from 'react'

// custom components
import CustomToolTip from '../../../components/Custom/CustomToolTip'
import CopyToClipboard from '../../../utils/vinCopyToClickbord/vinCopyToClickbord'

export const columns = [
  {
    name: 'VIN',
    sortable: true,
    selector: ({ vin_number }) => vin_number,
    cell: ({ vin_number }) => (
      <CustomToolTip label={vin_number}>
        <CopyToClipboard>
          <span>{vin_number}</span>
        </CopyToClipboard>
      </CustomToolTip>
    ),
  },
  {
    name: 'Added By',
    sortable: true,
    selector: ({ user }) => {
      if (user && user.first_name && user.last_name) {
        return `${user.first_name} ${user.last_name}`
      } else if (user && user.first_name) {
        return user.first_name
      } else {
        return 'Unknown'
      }
    },
    cell: ({ user }) => {
      // let users
      const capitalizedFirstName = user?.first_name
        ? user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
        : ''
      const capitalizedLastName = user?.last_name
        ? user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)
        : ''

      return (
        <CustomToolTip label={`${capitalizedFirstName} ${capitalizedLastName}`}>
          <span className='capitalize'>
            {capitalizedFirstName} {capitalizedLastName}
          </span>
        </CustomToolTip>
      )
    },
  },
  {
    name: 'Make',
    sortable: true,
    selector: ({ make }) => make,
  },
  {
    name: 'Model',
    sortable: true,
    selector: ({ model }) => model,
  },
  {
    name: 'Year',
    sortable: true,
    selector: ({ year }) => year,
  },
  {
    name: 'Buyer',
    sortable: true,
    selector: ({ vehicle_auction }) =>
      vehicle_auction?.awarded_buyer_id?.first_name &&
      vehicle_auction?.awarded_buyer_id?.last_name
        ? `${vehicle_auction?.awarded_buyer_id?.first_name} ${vehicle_auction?.awarded_buyer_id?.last_name}`
        : '',
    cell: ({ vehicle_auction }) => (
      <span className='lowercase'>
        {vehicle_auction?.awarded_buyer_id?.first_name &&
        vehicle_auction?.awarded_buyer_id?.last_name
          ? `${vehicle_auction?.awarded_buyer_id?.first_name} ${vehicle_auction?.awarded_buyer_id?.last_name}`
          : ''}
      </span>
    ),
  },
  {
    name: 'Price',
    sortable: true,
    selector: ({ vehicle_auction }) => vehicle_auction?.awarded_amount,
  },

  {
    name: '',
    sortable: false,
    selector: ({ action }) => action,
  },
]
