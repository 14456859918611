import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  currentUserId: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInSuccess: (state, action) => {
      state.currentUser = action.payload.token
      state.currentUserId = action.payload.user
    },
    signOut: (state) => {
      state.currentUserId = null
      state.currentUser = null
    },
  },
})

export const { signInSuccess, signOut } = userSlice.actions

export default userSlice.reducer
