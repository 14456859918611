import CustomToast from '../../components/Custom/CustomToast'

export const handleRequestError = (error) => {
  if (error.response) {
    CustomToast({
      message: error.response.data.message || 'Server Error!',
      type: 'error',
    })
  } else {
    CustomToast({
      message: 'Server Error!',
      type: 'error',
    })
  }
}
