import React, { Fragment, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomInput from '../../../custom/CustomInput'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../components/Icons'

const { FaRegEdit } = Icons

const validationSchema = Yup.object().shape({
  timeframe: Yup.number()
    .min(10, 'Timeframe must be greater than 10 minutes')
    .max(60, 'Timeframe must be less than or equal to 60')
    .required('Timeframe is required'),
})

const Edit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [timeframe, setTimeframe] = useState()

  const fetchTimeline = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/setting/offer/timeframe',
      )

      if (response.data.success) {
        setTimeframe(response.data.offer_time)
        formik.setFieldValue('timeframe', response.data.offer_time)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchTimeline()
  }, [])

  const handleSubmit = async (values) => {
    try {
      const response = await axiosInterceptorInstance.post(
        '/setting/offer/timeframe',
        values,
      )

      if (response.data.success) {
        CustomToast({
          message: response.data.message || 'Timeframe saved successfully!',
          type: 'success',
        })
        fetchTimeline()
        setEditMode(false)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      timeframe: '',
    },
    validateOnBlur: false,
  })

  const { isSubmitting } = formik

  return (
    <div>
      <CustomTitle backBtn customTitle='TimeFrame' />

      <CustomLoadingSection isLoading={isLoading}>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
          <div className='flex flex-col gap-2'>
            <div className='item_wrapper rounded-md p-4 bg-gray bg-opacity-10 border border-gray border-dashed border-opacity-10 text-gray hover:shadow-card duration-200 ease hover:-translate-y-1 transition-all relative'>
              <div className='flex flex-col gap-y-2'>
                <div className='card-headings'>
                  <span className='font-light text-sm uppercase'>
                    Offer Time Frame
                  </span>
                </div>

                <div className='user-counts'>
                  <span className='text-2xl'>{timeframe} min</span>
                </div>
              </div>
              {!editMode && (
                <button
                  className='absolute top-2 right-2'
                  onClick={() => setEditMode(true)}
                >
                  <FaRegEdit size={20} className='text-primary' />
                </button>
              )}
            </div>
            {editMode && (
              <Fragment>
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit} className='w-full'>
                    <div className='flex flex-col w-full gap-10'>
                      <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
                        <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                          <Field
                            name='timeframe'
                            type='number'
                            placeholder='Timeframe( in min )'
                            component={CustomInput}
                            className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                          />
                        </div>

                        <button
                          type='submit'
                          className='btn btn-primary-ico w-20 ml-auto'
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </Fragment>
            )}
          </div>
        </div>
      </CustomLoadingSection>
    </div>
  )
}

export default Edit
