import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StepProvider } from './context/useStepContext.js'
import { store } from './redux/store.js'

import App from './App'

import './index.css'
import 'react-tippy/dist/tippy.css'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <StepProvider>
      <App />
      <ToastContainer />
    </StepProvider>
  </Provider>,
)
