import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

const CustomStopWatch = ({ end_date }) => {
  const calculateTimeRemaining = () => {
    const now = new Date()

    const end = new Date(end_date?.replace(/-/g, '/'))
    const timeDiff = end - now

    if (timeDiff <= 0) {
      return { minutes: 0, seconds: 0 }
    }

    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

    return { minutes, seconds }
  }

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const { minutes, seconds } = timeRemaining

  const formatTime = (value) => (value < 10 ? `0${value}` : value)

  return <Fragment>{`${formatTime(minutes)}:${formatTime(seconds)}`}</Fragment>
}

CustomStopWatch.propTypes = {
  end_date: PropTypes.string.isRequired,
}

export default CustomStopWatch
