import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

// add vehicle components
import VinDetails from '../views/Seller/Vehicle/Add/VinDetails'
import VehicleDetails from '../views/Seller/Vehicle/Add/VehicleDetails'
import VehicleImages from '../views/Seller/Vehicle/Add/VehicleImages'
import VehicleConditions from '../views/Seller/Vehicle/Add/VehicleConditions'

// edit vehicle components
import EditVinDetails from '../views/Seller/Vehicle/Edit/VinDetails'
import EditVehicleDetails from '../views/Seller/Vehicle/Edit/VehicleDetails'
import EditVehicleImages from '../views/Seller/Vehicle/Edit/VehicleImages'
import EditVehicleConditions from '../views/Seller/Vehicle/Edit/VehicleConditions'

const StepContext = createContext()

export function useStepContext() {
  return useContext(StepContext)
}

export function StepProvider({ children }) {
  const steps = [
    { stepNumber: 1, component: VinDetails, label: 'VIN Details' },
    {
      stepNumber: 2,
      component: VehicleDetails,
      label: 'Vehicle Details',
    },
    {
      stepNumber: 3,
      component: VehicleConditions,
      label: 'Vehicle Condition',
    },
    {
      stepNumber: 4,
      component: VehicleImages,
      label: 'Vehicle Images',
    },
  ]
  const editSteps = [
    { stepNumber: 1, component: EditVinDetails, label: 'VIN Details' },
    {
      stepNumber: 2,
      component: EditVehicleDetails,
      label: 'Vehicle Details',
    },
    {
      stepNumber: 3,
      component: EditVehicleConditions,
      label: 'Vehicle Condition',
    },
    {
      stepNumber: 4,
      component: EditVehicleImages,
      label: 'Vehicle Images',
    },
  ]

  const [step, setStep] = useState(1)
  const [editStep, setEditStep] = useState(1)

  const handleStepClick = (stepNumber) => {
    setStep(stepNumber)
  }
  const handleEditStepClick = (stepNumber) => {
    setEditStep(stepNumber)
  }

  return (
    <StepContext.Provider
      value={{
        step,
        steps,
        handleStepClick,
        editStep,
        editSteps,
        handleEditStepClick,
      }}
    >
      {children}
    </StepContext.Provider>
  )
}

StepProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
