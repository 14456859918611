import React, { Fragment, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomInput from '../../../../custom/CustomInput'
import CustomRadioInput from '../../../../custom/CustomRadioInput'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomDeleteUpdateModal from '../../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { FaRegEye, FiTrash } = Icons

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is required.'),
  message: Yup.string().trim().required('Message is required.'),
  target_role: Yup.string()
    .trim()
    .required('Target Role is required.')
    .oneOf(['seller', 'buyer', 'all'], 'Invalid Target Role'),
  status: Yup.string()
    .trim()
    .required('Status is required.')
    .oneOf(['active', 'inactive'], 'Invalid Status'),
})

const roles = [
  { value: 'seller', label: 'Seller' },
  { value: 'buyer', label: 'Buyer' },
  { value: 'all', label: 'All' },
]
const status = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
]

const Edit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const [isloading, setisloading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const fetchAnnouncementDetails = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/announcements/details/${id}`,
      )
      setIsLoading(false)
      if (response.data.success) {
        formik.setValues({
          ...formik.values,
          ...response?.data?.announcementDetails,
        })
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchAnnouncementDetails()
  }, [id])

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.put(
        `/announcements/edit/${id}`,
        { ...values },
      )
      setIsLoading(false)
      if (response.data.success) {
        CustomToast({
          message:
            response.data.message || 'Announcement updated successfully!',
          type: 'success',
        })
        resetForm()
        navigate('/announcement/all')
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      title: '',
      message: '',
      target_role: '',
      status: '',
    },
    validateOnBlur: false,
  })

  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/announcements/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'Announcement deleted successfully',
        type: 'success',
      })

      setisloading(false)

      setdeleteuserModel('')
      navigate('/announcement/all')
    } catch (error) {
      setisloading(false)

      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }

  return (
    <Fragment>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='Edit' />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className='w-full'>
            <div className='flex flex-col w-full gap-10'>
              <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
                <div className='flex justify-end'>
                  <CustomToolTip label='View Announcement'>
                    <NavLink
                      to={`/announcement/view/${id}`}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                    >
                      <FaRegEye size={30} />
                    </NavLink>
                  </CustomToolTip>
                  <CustomToolTip label='Delete Announcement'>
                    <button
                      type='button'
                      onClick={() => deleteModalhandler(id)}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                    >
                      <FiTrash />
                    </button>
                  </CustomToolTip>
                </div>
                <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                  <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='title'
                      label='Title'
                      placeholder='Title'
                      component={CustomInput}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                      required={true}
                    />
                  </div>
                  <div className='max-lg:w-full lg:flex-auto'>
                    <Field
                      name='target_role'
                      label='Target Role'
                      component={CustomRadioInput}
                      options={roles}
                      className='custom-radio'
                      required={true}
                    />
                  </div>
                  <div className='max-lg:w-full lg:flex-auto'>
                    <Field
                      name='status'
                      label='Status'
                      component={CustomRadioInput}
                      options={status}
                      className='custom-radio'
                      required={true}
                    />
                  </div>
                  <div className='w-full'>
                    <div className='w-full'>
                      <h5 className='flex items-center gap-1 text-base font-semibold text-gray mb-3'>
                        Message
                        <span className='text-danger'>*</span>
                      </h5>
                    </div>
                    <Field
                      component='textarea'
                      rows='4'
                      className='form-textarea'
                      name='message'
                      label='message'
                    />
                    <ErrorMessage
                      name='message'
                      component='div'
                      className='text-xs font-normal text-danger mt-1 ml-1'
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary-ico w-20 ml-auto'
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>
            {deleteuserModel && (
              <CustomDeleteUpdateModal
                closeModal={closeModal}
                isloading={isloading}
                onSubmit={submitdeletehandler}
                message={'Are you sure you want to delete this announcement?'}
              />
            )}
          </form>
        </FormikProvider>
      </CustomLoadingSection>
    </Fragment>
  )
}

export default Edit
