import React, { Fragment, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// custom components
import CustomInput from '../../../custom/CustomInput'
import CustomSelect from '../../../custom/CustomSelect'
import CustomMultiSelect from '../../../custom/CustomMultiSelect'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomToolTip from '../../../components/Custom/CustomToolTip'

// utils
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'

import { Icons } from '../../../components/Icons'

const { FiUpload, MdDelete, MdAdd, MdArrowRightAlt, MdOutlineCheck } = Icons

const provinceList = [
  { value: 'Alberta', label: 'Alberta' },
  { value: 'British Columbia', label: 'British Columbia' },
  { value: 'Manitoba', label: 'Manitoba' },
  { value: 'New Brunswick', label: 'New Brunswick' },
  { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
  { value: 'Nova Scotia', label: 'Nova Scotia' },
  { value: 'Northwest Territories', label: 'Northwest Territories' },
  { value: 'Nunavut', label: 'Nunavut' },
  { value: 'Ontario', label: 'Ontario' },
  { value: 'Prince Edward Island', label: 'Prince Edward Island' },
  { value: 'Quebec', label: 'Quebec' },
  { value: 'Saskatchewan', label: 'Saskatchewan' },
  { value: 'Yukon', label: 'Yukon' },
]

const sellerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Dealership name is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
  contact_person: Yup.string().required('Contact person is required'),
  email: Yup.string().email('Invalid email address'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter a valid URL',
  ),
  address: Yup.array().of(
    Yup.object().shape({
      street: Yup.string().required('Street is required'),
      city: Yup.string().required('City is required'),
      province: Yup.string().required('Province is required'),
      postcode: Yup.string().required('Postcode is required'),
    }),
  ),
})

const buyerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Dealer name is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
  contact_person: Yup.string().required('Contact person is required'),
  intrested_province: Yup.array().min(
    1,
    'Please select at least one province from where you wish to purchase a vehicle.',
  ),
  email: Yup.string().email('Invalid email address'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter a valid URL',
  ),
})

const DealerDetails = ({ setStep }) => {
  const [userDetails, setUserDetails] = useState()
  const [userDealerDetails, setUserDealerDetails] = useState()

  const [searchParams] = useSearchParams()
  const user = searchParams.get('user')

  const fetchUserData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/user/detail/${user}`,
      )
      if (response.data.success) {
        setUserDetails(response.data.userdetails)

        if (
          response.data.userdetails.is_dealer_details_completed &&
          response.data.userdetails.user_dealer_details
        ) {
          setUserDealerDetails(response.data.userdetails?.user_dealer_details)
          const { address, dealer_logo } =
            response.data.userdetails?.user_dealer_details //eslint-disable-line no-unsafe-optional-chaining

          formik.setValues({
            ...formik.values,
            ...response.data.userdetails?.user_dealer_details,
          })
          formik.setFieldValue('dealer_logo_preview', dealer_logo)

          if (Array.isArray(address) && address.length > 0) {
            address.forEach((addr, index) => {
              formik.setFieldValue(
                `address[${index}].street`,
                addr.street || '',
              )
              formik.setFieldValue(`address[${index}].city`, addr.city || '')
              formik.setFieldValue(
                `address[${index}].province`,
                addr.province || '',
              )
              formik.setFieldValue(
                `address[${index}].postcode`,
                addr.postcode || '',
              )
            })
          }
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  //eslint-disable-next-line no-unused-vars
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData()

      Object.keys(values).forEach((key) => {
        if (key === 'address') {
          values[key].forEach((address, index) => {
            Object.entries(address).forEach(([subKey, subValue]) => {
              formData.append(`${key}[${index}][${subKey}]`, subValue)
            })
          })
        } else if (values[key] instanceof File) {
          formData.append(key, values[key])
        } else {
          formData.append(key, values[key])
        }
      })

      if (userDealerDetails || userDealerDetails?.length) {
        const response = await axiosInterceptorInstance.put(
          `/user/dealer/update/${userDealerDetails?._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          setStep(3)
        }
      } else {
        const response = await axiosInterceptorInstance.post(
          `/user/dealer/create/${user}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          setStep(3)
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const formik = useFormik({
    validationSchema:
      userDetails?.role === 'buyer'
        ? buyerValidationSchema
        : sellerValidationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      name: '',
      email: '',
      phone: '',
      contact_person: '',
      website: '',
      dealer_logo: '',
      dealer_logo_preview: '',
      address: [{ street: '', city: '', province: '', postcode: '' }],
      intrested_province: [],
    },
    validateOnBlur: false,
  })

  const {
    isSubmitting,
    values: { intrested_province },
  } = formik

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0]
    formik.setFieldValue('dealer_logo', file)

    if (file) {
      const previewUrl = URL.createObjectURL(file)
      formik.setFieldValue('dealer_logo_preview', previewUrl)
    }
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className='w-full'>
        <div className='flex flex-col w-full gap-10'>
          <div>
            <div className='title title-primary text-left mb-3'>
              <h2 className='heading-2'>Dealer Information</h2>
            </div>
            <div className='content text-left'>
              <p>
                Please provide dealer name, phone number, email address and etc.
              </p>
            </div>
          </div>
          <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
            <div className='max-sm:w-full flex-[0_0_auto] flex flex-wrap justify-center items-center'>
              <label className='flex flex-col items-start justify-start w-full last:border-0 border-b border-gray border-opacity-10 pb-3 last:pb-0 mb:pb-0 md:border-0'>
                <div className='w-24 h-24 rounded-full bg-gray bg-opacity-5 cursor-pointer border border-dashed border-gray border-opacity-20 relative overflow-hidden'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    name='Profile'
                    onChange={(event) => handleImageChange(event)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.dealer_logo_preview ? (
                    <img
                      src={formik.values.dealer_logo_preview}
                      alt='Preview'
                      className='w-full h-full object-cover absolute top-0 left-0'
                      onError={(e) => userBrokenImages(e)}
                    />
                  ) : (
                    <div className='w-full h-full absolute top-0 flex items-center justify-center object-cover bg-light-gray text-primary text-3xl rounded-full p-0'>
                      {formik.values.name?.substring(0, 2).toUpperCase()}
                    </div>
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    <div className='flex flex-col items-center justify-center mx-auto mb-1 text-primary w-8 h-8 rounded-full bg-white bg-opacity-50 hover:bg-primary hover:text-white'>
                      <FiUpload />
                    </div>
                  </div>
                </div>
                {formik.touched.dealer_logo && formik.errors.dealer_logo && (
                  <div className='text-xs font-normal text-danger'>
                    {formik.errors.dealer_logo}
                  </div>
                )}
              </label>

              {formik.touched.dealer_logo && formik.errors.dealer_logo && (
                <div className='text-xs font-normal text-danger'>
                  {formik.errors.dealer_logo}
                </div>
              )}
            </div>

            <div className='w-full sm:max-w-[calc(100%_-_0px)]'>
              <Field
                name='name'
                label='Dealership Name/Business Name'
                placeholder='Dealer Name/Business Name'
                component={CustomInput}
                required={true}
                className='form-field'
              />
            </div>

            <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
              <Field
                name='contact_person'
                label='Primary Contact Person'
                placeholder='Primary Contact Person'
                component={CustomInput}
                required={true}
                className='form-field'
              />
            </div>
            <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
              <Field
                type='text'
                name='phone'
                label='Contact Number'
                required
                placeholder='Contact Phone Number'
                component={CustomInput}
                className='form-field'
              />
            </div>
            <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
              <Field
                name='email'
                label='Contact Email'
                placeholder='Contact Email'
                component={CustomInput}
                className='form-field'
              />
            </div>

            <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
              <Field
                name='website'
                type='url'
                label='Website URL'
                placeholder='Website URL'
                component={CustomInput}
                className='form-field'
              />
            </div>
            {userDetails?.role === 'buyer' && (
              <div className='w-full'>
                <h5 className='flex items-center gap-1 text-base font-semibold text-gray mb-3'>
                  Please specify which province you are interested in buying the
                  vehicle in? <span className='text-danger'>*</span>
                </h5>

                <Field
                  name='intrested_province'
                  placeholder='Province*'
                  component={CustomMultiSelect}
                  options={provinceList}
                  value={intrested_province.map((value) => ({
                    value: value,
                    label: value,
                  }))}
                />
              </div>
            )}
            <FieldArray name='address'>
              {({ push, remove }) => (
                <div className='w-full'>
                  {formik.values.address.length === 0 &&
                    push({
                      street: '',
                      city: '',
                      province: '',
                    })}
                  <div className='flex justify-between items-center'>
                    <div className='title title-primary'>
                      <h4 className='heading-4'>
                        {userDetails?.role === 'buyer'
                          ? 'Dealer Location'
                          : 'Pickup Location'}
                      </h4>
                    </div>
                  </div>

                  {formik.values.address?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className='mt-5 pt-5 w-full border-t border-t-gray/10'
                      >
                        <div className='flex justify-between gap-2 items-center mb-5'>
                          <div className='title title-gray'>
                            <h4 className='subHeading-4 font-semibold'>
                              Location {index + 1}
                            </h4>
                          </div>
                          <div className='flex justify-end gap-2'>
                            {formik.values.address.length > 1 && (
                              <CustomToolTip label='Delete address'>
                                <button
                                  className='btn-primary-ico rounded-full'
                                  type='button'
                                  onClick={() => remove(index)}
                                >
                                  <MdDelete className='m-1' />
                                </button>
                              </CustomToolTip>
                            )}
                          </div>
                        </div>

                        <div className='flex flex-wrap items-start justify-start w-full mx-0 p-0 gap-x-5 gap-y-8'>
                          <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                            <Field
                              name={`address[${index}].street`}
                              placeholder='Street'
                              className='form-field'
                              label='Street'
                              component={CustomInput}
                              required={userDetails?.role === 'seller'}
                            />
                          </div>

                          <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                            <Field
                              component={CustomInput}
                              name={`address[${index}].city`}
                              className='form-field'
                              placeholder='City'
                              label='City'
                              required={userDetails?.role === 'seller'}
                            />
                          </div>

                          <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                            <Field
                              name={`address[${index}].province`}
                              label='Province'
                              placeholder='Select Province'
                              component={CustomSelect}
                              options={provinceList}
                              value={
                                provinceList.find(
                                  (option) => option.value === item.province,
                                ) ?? []
                              }
                              required={userDetails?.role === 'seller'}
                            />
                          </div>
                          <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                            <Field
                              name={`address[${index}].postcode`}
                              label='Postcode'
                              placeholder='Enter postcode'
                              component={CustomInput}
                              className='form-field'
                              required={userDetails?.role === 'seller'}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className='flex mt-5 justify-end'>
                    <button
                      className='btn btn-dark-ico  '
                      type='button'
                      onClick={() =>
                        push({
                          street: '',
                          city: '',
                          province: '',
                          postcode: '',
                        })
                      }
                    >
                      <MdAdd className='m-1' />
                      Add Address
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
          <div className='flex items-center justify-center w-full gap-5 mt-10 flex-wrap md:items-center md:justify-between'>
            {setStep && (
              <button
                type='reset'
                className='prev next-prev-button xs:flex-auto xs:order-1 order-2'
                onClick={() => setStep(1)}
              >
                <MdArrowRightAlt /> Back
              </button>
            )}

            <button
              type='submit'
              className='btn btn-primary-ico ml-auto w-full xs:w-auto xs:order-2 order-1'
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className='h-5 w-5 mx-auto animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
              ) : (
                <Fragment>
                  <span>Save & Continue</span>
                  <MdOutlineCheck />
                </Fragment>
              )}
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

DealerDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default DealerDetails
