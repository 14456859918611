import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

// components
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

// utils
import axiosInterceptorInstance from '../utils/axios/axiosInterceptorInstance'
import { getFirebaseToken } from '../utils/firebaseNotification/firebaseConfig'
import { handleRequestError } from '../utils/axios/handleRequestError'

const DefaultLayout = () => {
  const StoreNotificationToken = async (token) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/push/notification/store/token`,
        { token },
      )
      if (response.data.success) {
        // eslint-disable-line no-empty
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        StoreNotificationToken(firebaseToken)
      })
      .catch((err) =>
        console.error(
          'An error occured while retrieving firebase token. ',
          err,
        ),
      )
  }

  useEffect(() => {
    handleGetFirebaseToken()
  }, [])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className='dark:bg-boxdark-2 dark:text-bodydark'>
      <div className='flex h-screen overflow-hidden'>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className='relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto bg-gray/5'>
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main>
            <div className='p-5 mx-auto max-sm:p-4'>
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout
