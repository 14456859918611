import React from 'react'

// custom components
import CustomToolTip from '../../../components/Custom/CustomToolTip'

export const columns = [
  {
    name: 'Country Code',
    sortable: true,
    selector: ({ country_code }) => country_code,
  },
  {
    name: 'State Code',
    sortable: true,
    selector: ({ state_code }) => state_code,
  },

  {
    name: 'Rate',
    sortable: true,
    selector: ({ rate }) => rate,
    format: ({ rate }) => `${rate}%`,
  },
  {
    name: 'Tax Name',
    sortable: true,
    selector: ({ tax_name }) => tax_name,
  },
  {
    name: 'Priority',
    sortable: true,
    selector: ({ priority }) => priority,
  },
  {
    name: 'Created At',
    sortable: true,
    selector: ({ created_at }) => created_at,
    format: ({ created_at }) => (
      <CustomToolTip
        label={new Date(created_at).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      >
        <span>
          {new Date(created_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </span>
      </CustomToolTip>
    ),
  },
  {
    name: '',
    sortable: false,
    selector: ({ action }) => action,
  },
]
