import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import PropTypes from 'prop-types'

import CustomInput from '../../../../custom/CustomInput'
import CustomToast from '../../../../components/Custom/CustomToast'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { MdArrowRightAlt, MdOutlineCheck } = Icons

const validationSchema = Yup.object().shape({
  bank_name: Yup.string().required('Bank name is required'),
  account_number: Yup.string().required('Account number is required'),
  institution_number: Yup.string().required('Institution number is required'),
  transit_number: Yup.string().required('Transit number is required'),
})

const BuyerFinancialDetails = ({ setStep }) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [userFinancialDetails, setUserFinancialDetails] = useState()

  const [searchParams] = useSearchParams()
  const user = searchParams.get('user')
  const [userDetails, setUserDetails] = useState()

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/user/detail/${user}`,
      )
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        if (
          response.data.userdetails.is_financial_details_completed &&
          response.data.userdetails.user_financial_details
        ) {
          setUserFinancialDetails(
            response.data.userdetails?.user_financial_details,
          )
        }
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSubmit = async (values, { resetForm }) => {
    if (userFinancialDetails?._id) {
      try {
        const response = await axiosInterceptorInstance.put(
          `/user/financial/update/${userFinancialDetails._id}`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          resetForm()
          navigate('/user/all')
        }
      } catch (error) {
        handleRequestError(error)
      }
    } else {
      try {
        const response = await axiosInterceptorInstance.post(
          `/user/financial/create/buyer/${userDetails._id}`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          navigate('/user/all')
        }
      } catch (error) {
        handleRequestError(error)
      }
    }
  }
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      bank_name: 'New Bank',
      account_number: '000123456789',
      institution_number: '000',
      transit_number: '11000',
      swift_code: '',
    },
  })

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full max-lg:px-5 '>
          <div className='flex flex-col w-full gap-5 lg:gap-10 '>
            <div>
              <div className='mb-3 text-left title title-primary'>
                <h2 className='heading-2'>Financial Information</h2>
              </div>
              <div className='text-left content'>
                <p>
                  Please provide your bank name, account name, transit number,
                  institution number.
                </p>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full gap-5 p-0 m-0 lg:gap-y-8'>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='bank_name'
                  label='Bank Name'
                  placeholder='Bank Name'
                  component={CustomInput}
                  className='form-field'
                  required={true}
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='account_number'
                  label='Account Number'
                  placeholder='Account Number'
                  component={CustomInput}
                  className='form-field'
                  required={true}
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='transit_number'
                  label='Transit Number'
                  placeholder='Transit Nnumber'
                  component={CustomInput}
                  className='form-field'
                  required={true}
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='institution_number'
                  label='Institution Number'
                  placeholder='Institution Number'
                  component={CustomInput}
                  className='form-field'
                  required={true}
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='swift_code'
                  label='Swift Code'
                  placeholder='Swift Code'
                  component={CustomInput}
                  className='form-field'
                />
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-center w-full gap-5 lg:mt-10 md:items-center md:justify-between'>
              <button
                type='button'
                className='order-2 prev next-prev-button xs:flex-auto xs:order-1'
                onClick={() => setStep(1)}
              >
                <MdArrowRightAlt /> Back
              </button>
              <button
                type='submit'
                className='order-1 w-full ml-auto btn btn-primary-ico xs:w-auto xs:order-2'
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    <span>Submit</span>
                    <MdOutlineCheck />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

BuyerFinancialDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default BuyerFinancialDetails
