import React, { useRef } from 'react'
import CustomToast from '../../components/Custom/CustomToast'
import PropTypes from 'prop-types'

const CopyToClipboard = ({ children }) => {
  const textRef = useRef(null)

  const copyText = () => {
    const text = textRef.current.innerText

    navigator.clipboard
      .writeText(text)
      .then(() =>
        CustomToast({
          type: 'success',
          message: 'VIN copied to clipboard',
        }),
      )
      .catch(() =>
        CustomToast({
          type: 'error',
          message: 'Failed to copy text',
        }),
      )
  }

  return (
    <span className='cursor-pointer' onClick={copyText} ref={textRef}>
      {children}
    </span>
  )
}

CopyToClipboard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CopyToClipboard
