import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import Fancybox from '../../../../components/Custom/ImageViewer'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomCarDetails from '../../../../components/Custom/CustomCarDetails'
import CustomStopWatch from '../../../../components/Custom/CustomStopWatch'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

const View = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [carDetails, setCarDetails] = useState()

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/details/${id}`,
      )
      if (response.data.success) {
        setCarDetails(response.data.vehicleDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <section className='details-single lg:pb-0'>
        <CustomTitle backBtn customTitle='Vehicle Details' />

        <div className='flex flex-wrap items-center justify-start m-0 p-0 w-full gap-8 mb-5'>
          <div className='md:flex-2'>
            <div className='listing-detail-title flex gap-2'>
              <h2 className='text-3xl text-gray font-bold'>
                {carDetails?.year}&nbsp;
                {carDetails?.make}&nbsp;
                {carDetails?.model}
              </h2>
              {carDetails?.vehicle_auction?.status === 'live' && (
                <span className='bg-primary bg-opacity-10 text-primary py-2 px-5 text-center text-xs font-medium rounded-full '>
                  <CustomStopWatch
                    end_date={carDetails?.vehicle_auction?.end_auction_time}
                  />{' '}
                  min left
                </span>
              )}
              {carDetails?.vehicle_auction?.status === 'scheduled' && (
                <>
                  {carDetails?.vehicle_auction?.launch_auction_date && (
                    <span className='flex flex-wrap items-center order-2 px-5 py-2 text-xs font-medium text-center rounded-full bg-primary bg-opacity-10 text-primary'>
                      {moment(
                        carDetails?.vehicle_auction?.launch_auction_date,
                        'YYYY-MM-DDTHH:mm:ss',
                      ).format('MMM D, YYYY')}
                      <span className='mx-2 w-[1px] h-[17px] bg-primary/40 block'></span>
                      {moment(
                        carDetails?.vehicle_auction?.launch_auction_date,
                        'YYYY-MM-DDTHH:mm:ss',
                      ).format('h:mm A')}
                      &nbsp;
                      {carDetails?.vehicle_auction?.launch_auction_timezone &&
                      carDetails?.vehicle_auction?.launch_auction_timezone ===
                        'Canada/Pacific' ? (
                        <span className='font-medium'>
                          (<span className='font-semibold'>PT</span>)
                        </span>
                      ) : carDetails?.vehicle_auction
                          ?.launch_auction_timezone === 'Canada/Mountain' ? (
                        <span className='font-medium'>
                          (<span className='font-semibold'>MT</span>)
                        </span>
                      ) : carDetails?.vehicle_auction
                          ?.launch_auction_timezone === 'Canada/Central' ? (
                        <span className='font-medium'>
                          (<span className='font-semibold'>CT</span>)
                        </span>
                      ) : carDetails?.vehicle_auction
                          ?.launch_auction_timezone === 'Canada/Eastern' ? (
                        <span className='font-medium'>
                          (<span className='font-semibold'>ET</span>)
                        </span>
                      ) : carDetails?.vehicle_auction
                          ?.launch_auction_timezone === 'Canada/Atlantic' ? (
                        <span className='font-medium'>
                          (<span className='font-semibold'>AT</span>)
                        </span>
                      ) : null}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className='m-0 p-0 w-full border-b border-gray border-opacity-10 pb-10 mb-[40px] last:mb-0'>
          <Fancybox vehicleImages={carDetails?.vehicle_image} />
        </div>
        <CustomCarDetails carDetails={carDetails} />
      </section>
    </CustomLoadingSection>
  )
}

export default View
