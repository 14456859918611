import React from 'react'
import PropTypes from 'prop-types'

import { Icons } from '../../Icons'

const { IoCloseOutline, CiWarning } = Icons

const CustomDeleteUpdateModal = ({
  closeModal,
  isloading,
  onSubmit,
  message,
}) => {
  return (
    <div className='fixed top-0 left-0 z-[9999] flex h-full min-h-screen w-full items-center justify-center bg-transparent px-4 py-5'>
      <div className='w-[400px] rounded-lg shadow bg-gray !py-6 sm:!py-12 px-8 text-center md:p-5 text-white relative'>
        <div
          onClick={closeModal}
          className='close-mark absolute top-5 right-5 text-2xl cursor-pointer'
        >
          <IoCloseOutline />
        </div>
        <div className='flex justify-center warning-sign text-5xl mb-4'>
          <CiWarning />
        </div>
        <p className='mb-5 sm:mb-10 font-medium'>{message}</p>

        <div className='mt-3 flex flex-wrap flex-row-reverse gap-4 justify-center'>
          <button
            disabled={isloading}
            onClick={closeModal}
            className='rounded border border-transparent py-2 px-4 font-medium bg-white text-primary'
          >
            Cancel
          </button>
          <button
            disabled={isloading}
            onClick={onSubmit}
            className={`rounded border border-primary hover:border-transparent py-2 px-4 font-medium bg-primary text-white`}
          >
            {isloading ? (
              <div className='h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
            ) : (
              "Yes, I'm sure"
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

CustomDeleteUpdateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isloading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}

export default CustomDeleteUpdateModal
