import React from 'react'

const truncateMessage = (message, limit = 100) => {
  const regex = /(<([^>]+)>)/gi
  let plainText = message.replace(regex, '')
  if (plainText.length <= limit) return message
  let truncatedPlainText = plainText.substring(0, limit)
  let truncatedMessage = message.substring(0, truncatedPlainText.length)
  return truncatedMessage + '...'
}

export const columns = [
  {
    name: 'Title',
    sortable: true,
    selector: ({ title }) => title,
  },
  {
    name: 'Message',
    sortable: true,
    selector: ({ message }) => message,
    cell: ({ message }) => (
      <div
        title={message}
        dangerouslySetInnerHTML={{ __html: truncateMessage(message) }}
      />
    ),
  },
  {
    name: 'Send To',
    sortable: true,
    selector: ({ target_role }) => target_role,
  },

  {
    name: 'status',
    sortable: true,
    selector: ({ status }) => status,
  },
  {
    name: 'Created By',
    sortable: true,
    selector: ({ created_by }) => created_by?.username,
  },

  {
    name: 'Created At',
    sortable: true,
    selector: ({ created_at }) => created_at,
    format: ({ created_at }) => (
      <span
        title={new Date(created_at).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      >
        {new Date(created_at).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      </span>
    ),
  },
  {
    name: '',
    sortable: false,
    selector: ({ action }) => action,
  },
]
