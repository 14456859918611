import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { NavLink, useNavigate } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../utils/axios/handleRequestError'

// custom components
import CustomToast from '../../components/Custom/CustomToast'
import CustomDeleteUpdateModal from '../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../components/Custom/CustomLoadingSection'
import CopyToClipboard from '../../utils/vinCopyToClickbord/vinCopyToClickbord'

import { Icons } from '../../components/Icons'

const { FaUser, FaCar, FaRegEye, FiChevronDown } = Icons

const Home = () => {
  const navigate = useNavigate()

  const columnsPendingUsers = [
    {
      name: 'Email',
      sortable: true,
      selector: ({ email }) => email,
      cell: ({ email }) => (
        <CustomToolTip label={email}>
          <span title={email} className='lowercase'>
            {email}
          </span>
        </CustomToolTip>
      ),
    },
    {
      name: 'Phone Number',
      sortable: true,
      selector: ({ phone }) => phone,
    },
    {
      name: 'Actions',
      sortable: false,
      cell: (row) => (
        <div className='flex flex-wrap justify-center items-center gap-2'>
          <button
            onClick={() => userEditModalhandler(row._id)}
            className='btn-primary-a'
          >
            {row.is_admin_verified ? 'Reject' : 'Approve'}
          </button>
          <CustomToolTip label='View'>
            <NavLink
              to={`/user/view/${row._id}`}
              className='inline-block px-1 py-1 duration-300 ease-linear delay-150 rounded-full'
            >
              <FaRegEye size={16} />
            </NavLink>
          </CustomToolTip>
        </div>
      ),
    },
  ]

  const columnsLatestVehicles = [
    {
      name: 'VIN',
      sortable: true,
      selector: ({ vin_number }) => vin_number,
      cell: ({ vin_number }) => (
        <CustomToolTip label={vin_number}>
          <CopyToClipboard>
            <span>{vin_number}</span>
          </CopyToClipboard>
        </CustomToolTip>
      ),
    },

    {
      name: 'Make',
      sortable: true,
      selector: ({ make }) => make,
    },
    {
      name: 'Model',
      sortable: true,
      selector: ({ model }) => model,
    },
    {
      name: 'Year',
      sortable: true,
      selector: ({ year }) => year,
    },

    {
      name: 'Actions',
      sortable: false,
      cell: (row) => (
        <CustomToolTip label='View'>
          <NavLink
            to={`/vehicle/view/${row._id}`}
            className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
          >
            <FaRegEye />
          </NavLink>
        </CustomToolTip>
      ),
    },
  ]

  const [loading, setLoading] = useState(true)
  const [deskBordDetais, setDeskBordDetais] = useState({})
  const [userCount, setUserCount] = useState(0)
  const [userList, setUserList] = useState([])
  const [vehicleList, setvehicleList] = useState([])
  const [updateuserModel, setupdateuserModel] = useState('')
  const [isloading, setisloading] = useState(false)
  const [userDataLoading, setuserDataLoading] = useState(true)
  const [vehicleDataLoading, setVehicleDataLoading] = useState(true)

  const fetchVehiclelist = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/details/deskbord',
      )

      if (response.data.success) {
        setDeskBordDetais(response.data.vehicleDetails)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }

  const fetchUserlist = async () => {
    try {
      const response = await axiosInterceptorInstance.get('/user/count')

      if (response.data.success) {
        setUserCount(response.data.count)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }
  const latestPendingUsersData = async () => {
    setuserDataLoading(true)
    try {
      const response = await axiosInterceptorInstance.get('/user/latest')
      setUserList(response.data.userList)

      setuserDataLoading(false)
    } catch (error) {
      setuserDataLoading(false)
      handleRequestError(error)
    }
  }
  const latestVehiclesData = async () => {
    setVehicleDataLoading(true)
    try {
      const response = await axiosInterceptorInstance.get('/vehicle/latest')
      setvehicleList(response.data.vehicleList)

      setVehicleDataLoading(false)
    } catch (error) {
      setVehicleDataLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchVehiclelist()
    fetchUserlist()
    latestPendingUsersData()
    latestVehiclesData()
  }, [])

  const userEditModalhandler = async (userid) => {
    setupdateuserModel(userid)
  }
  const closeModal = () => {
    setupdateuserModel('')
  }
  const submitedithandler = async () => {
    setisloading(true)
    try {
      const response = await axiosInterceptorInstance.put(
        `/user/update/${updateuserModel}`,
      )

      latestPendingUsersData()
      CustomToast({
        message:
          response.data.message ||
          'User verification status updated successfully',
        type: 'success',
      })
      setisloading(false)
      setLoading(false)
      setupdateuserModel(false)
    } catch (error) {
      setisloading(false)
      setLoading(false)
      setupdateuserModel(false)
      handleRequestError(error)
    }
  }

  const itemsData = [
    {
      icon: <FaUser />,
      iconBackground: '',
      value: userCount?.buyerCount || 0,
      label: 'Buyers',
    },
    {
      icon: <FaUser />,
      iconBackground: '',
      value: userCount?.sellerCount || 0,
      label: 'Sellers',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value: deskBordDetais?.total || 0,
      label: 'All Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'draft')
          ?.count || 0,
      label: 'Draft Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'parking')
          ?.count || 0,
      label: 'Parking Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'live')
          ?.count || 0,
      label: 'Live Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find(
          (status) => status.status === 'scheduled',
        )?.count || 0,
      label: 'Scheduled Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'pending')
          ?.count || 0,
      label: 'Pending Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'closed')
          ?.count || 0,
      label: 'Closed Vehicles',
    },
    {
      icon: <FaCar />,
      iconBackground: '',
      value:
        deskBordDetais?.statuses?.find((status) => status.status === 'sold')
          ?.count || 0,
      label: 'Sold Vehicles',
    },
  ]
  const handleUserRowClick = (row) => {
    navigate(`/user/view/${row._id}`)
  }
  const handleVehicleRowClick = (row) => {
    navigate(`/vehicle/view/${row._id}`)
  }
  return (
    <Fragment>
      <CustomLoadingSection isLoading={loading}>
        <Fragment>
          <div className='flex flex-wrap items-start m-0 mb-10 p-0 gap-[30px] w-full relative'>
            <div className='flex-auto'>
              <div className='p-8 bg-white/85 rounded-[16px] drop-shadow'>
                <div className='grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5'>
                  {itemsData.map((item, index) => (
                    <div
                      key={index}
                      className='p-5 duration-300 delay-150 ease-linear border rounded-[16px] border-gray border-opacity-10 item_wrapper bg-primary/5 hover:bg-white hover:shadow-lg hover:-translate-y-1'
                    >
                      <div className='flex flex-col gap-y-4'>
                        <span
                          className={`flex flex-wrap items-center justify-center w-8 h-8 text-white rounded-full bg-primary ${item.iconBackground}`}
                        >
                          {item.icon}
                        </span>
                        <div className='flex flex-col mb-0 user-count gap-y-2'>
                          <span className='text-2xl font-semibold'>
                            {item.value}
                          </span>
                          <span className='text-base font-light'>
                            {item.label}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 m-0 p-0 gap-[30px] w-full'>
            <div className='w-full p-7 bg-white/85 rounded-lg drop-shadow'>
              <div className='title title-primary relative !pl-5 !mb-5 before:content-[""] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-0 before:w-[10px] before:h-[1px] before:bg-primary'>
                <h3>Latest Pending Users</h3>
              </div>
              <DataTable
                columns={columnsPendingUsers}
                data={userList}
                sortIcon={<FiChevronDown size={10} />}
                persistTableHead={true}
                noDataComponent={
                  <Fragment>
                    <div className='m-5'>There are no pending requests.</div>
                  </Fragment>
                }
                progressPending={userDataLoading}
                progressComponent={
                  <div className='flex items-center justify-center py-5'>
                    <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
                  </div>
                }
                onRowClicked={handleUserRowClick}
                pointerOnHover
              />
            </div>
            <div className='w-full p-7 bg-white/85 rounded-lg drop-shadow'>
              <div className='title title-primary relative !pl-5 !mb-5 before:content-[""] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-0 before:w-[10px] before:h-[1px] before:bg-primary'>
                <h3>Latest Vehicles</h3>
              </div>
              <DataTable
                columns={columnsLatestVehicles}
                data={vehicleList}
                sortIcon={<FiChevronDown size={10} />}
                persistTableHead={true}
                noDataComponent={
                  <Fragment>
                    <div className='m-5'>
                      There are no vehicle listings available.
                    </div>
                  </Fragment>
                }
                progressPending={vehicleDataLoading}
                progressComponent={
                  <div className='flex items-center justify-center py-5'>
                    <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
                  </div>
                }
                onRowClicked={handleVehicleRowClick}
                pointerOnHover
              />
            </div>
          </div>
          {updateuserModel && (
            <CustomDeleteUpdateModal
              closeModal={closeModal}
              isloading={isloading}
              onSubmit={submitedithandler}
              message={
                userList.find((user) => user._id === updateuserModel)
                  ?.is_admin_verified
                  ? 'Do you  want to disapprove this user?'
                  : 'Do you  want to approve this user?'
              }
            />
          )}
        </Fragment>
      </CustomLoadingSection>
    </Fragment>
  )
}

export default Home
