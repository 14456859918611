import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError.js'

// custom components
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomUserTable from '../../../components/Custom/CustomUserTable.js'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { columns } from './columns'
import { getToken } from '../../../utils/cookies/cookies.js'

// config
import configManager from '../../../config/configManager.js'

const { WEBSITE_URL } = configManager()

const PendingUser = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const [updateuserModel, setupdateuserModel] = useState('')
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const [isloading, setisloading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/user/pending/list?limit=${pageSize}&page=${currentPage}&search=${searchText}`,
      )
      setUserList(response.data.userList)
      setTotalRecords(response.data.totalRecords)
      setTotalPages(response.data.totalPages)
      setCurrentPage(response.data.currentPage)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }
  const loginAsUserHandler = async (id) => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/auth/login-as-user/${id}`,
      )
      if (response.data.success) {
        if (!response.data.is_admin_verified) {
          const token = response.data.token
          const role = response.data.role
          const adminToken = getToken()
          setLoading(false)

          const url = `${WEBSITE_URL}/login?adminToken=${adminToken}&token=${token}&role=${role}`
          window.open(url, '_blank', 'noopener,noreferrer')
        }
      }
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }
  const fetchOnSearch = async () => {
    try {
      setSearchLoading(true)
      const response = await axiosInterceptorInstance.get(
        `/user/pending/list?limit=${pageSize}&page=${currentPage}&search=${searchText}`,
      )
      setUserList(response.data.userList)

      setTotalPages(response.data.totalPages)
      setCurrentPage(response.data.currentPage)
      setLoading(false)
      setSearchLoading(false)
    } catch (error) {
      setLoading(false)
      setSearchLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [pageSize, currentPage, updateuserModel])

  useEffect(() => {
    fetchOnSearch()
  }, [searchText])
  const handleSearch = async () => {
    fetchOnSearch()
  }

  const editModalhandler = async (userid) => {
    setupdateuserModel(userid)
  }

  const deleteModalhandler = (userid) => {
    setdeleteuserModel(userid)
  }

  const submitedithandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.put(
        `/user/update/${updateuserModel}`,
      )

      fetchData()
      setisloading(false)
      setLoading(false)
      setupdateuserModel(false)
      CustomToast({
        message:
          response.data.message ||
          'User verification status updated successfully.',
        type: 'success',
      })
    } catch (error) {
      setisloading(false)
      setLoading(false)
      setupdateuserModel(false)
      handleRequestError(error)
    }
  }

  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/user/delete/${deleteuserModel}`,
      )

      CustomToast({
        message: response.data.message || 'User deleted successfully.',
        type: 'success',
      })
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
      fetchData()
    } catch (error) {
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const closeModal = () => {
    setupdateuserModel('')
    setdeleteuserModel('')
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handlePerPageChange = (newPerPage, page) => {
    setPageSize(newPerPage)
    setCurrentPage(page)
  }
  const handleRowClick = (row) => {
    navigate(`/user/view/${row._id}`)
  }

  return (
    <CustomLoadingSection isLoading={loading}>
      <CustomTitle
        Serchbox={{
          searchText,
          setSearchText,
          handleSearch,
          placeholder: 'Search Pending Users',
        }}
        customTitle='Pending'
        createLink={'/user/add'}
        showCreateLink={true}
      />

      <CustomUserTable
        loading={loading}
        columns={columns}
        userList={userList}
        searchText={searchText}
        totalPages={totalPages}
        currentPage={currentPage}
        pageSize={pageSize}
        searchLoading={searchLoading}
        updateuserModel={updateuserModel}
        deleteuserModel={deleteuserModel}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handlePerPageChange={handlePerPageChange}
        handleRowClick={handleRowClick}
        editModalhandler={editModalhandler}
        deleteModalhandler={deleteModalhandler}
        submitedithandler={submitedithandler}
        submitdeletehandler={submitdeletehandler}
        isloading={isloading}
        closeModal={closeModal}
        noSearchResultMessage={'Your search did not match any results.'}
        noUserMessage={'There are no pending requests.'}
        loginAsUserHandler={loginAsUserHandler}
        totalRecords={totalRecords}
      />
    </CustomLoadingSection>
  )
}

export default PendingUser
