import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomDeleteUpdateModal from '../../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { FaRegEdit, FiTrash } = Icons

const View = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [taxDetails, setTaxDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isloading, setisloading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')

  const fetchTaxDetails = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(`/tax/details/${id}`)
      if (response.data.success) {
        setTaxDetails(response.data.taxDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchTaxDetails()
  }, [])

  const taxDetailsFields = [
    { label: 'State Code', value: taxDetails?.state_code },
    { label: 'Tax Name', value: taxDetails?.tax_name },
    { label: 'Rate', value: taxDetails?.rate },
    { label: 'Priority', value: taxDetails?.priority },
  ]
  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/tax/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'User deleted successfully',
        type: 'success',
      })

      setisloading(false)

      setdeleteuserModel('')
      navigate('/setting/tax/all')
    } catch (error) {
      setisloading(false)

      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }
  return (
    <div>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='View' />

        <section className='acc_user'>
          <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-5'>
            <div className='flex justify-end'>
              <CustomToolTip label='Edit'>
                <NavLink
                  to={`/setting/tax/edit/${id}`}
                  className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                  title='Edit'
                >
                  <FaRegEdit size={30} />
                </NavLink>
              </CustomToolTip>
              <CustomToolTip label='Delete'>
                <button
                  onClick={() => deleteModalhandler(id)}
                  className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                >
                  <FiTrash />
                </button>
              </CustomToolTip>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-5'>
              {taxDetailsFields.map((detail, index) => (
                <div key={index} className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <h5 className='text-gray text-sm font-500 mb-2'>
                    {detail.label}
                  </h5>
                  <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                    {detail.value}
                  </div>
                </div>
              ))}
            </div>

            {deleteuserModel && (
              <CustomDeleteUpdateModal
                closeModal={closeModal}
                isloading={isloading}
                onSubmit={submitdeletehandler}
                message={
                  'Are you sure you want to delete this tax configuration?'
                }
              />
            )}
          </div>
        </section>
      </CustomLoadingSection>
    </div>
  )
}

export default View
