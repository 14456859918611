import React, { useRef, useEffect, useState } from 'react'
import { Fancybox as NativeFancybox } from '@fancyapps/ui'

import '@fancyapps/ui/dist/fancybox/fancybox.css'
import PropTypes from 'prop-types'

// assets
import placeholderCar from '../../../assets/car/car-dummy.png'
import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'

function Fancybox(props) {
  const containerRef = useRef(null)
  const [vehiclePhotos, setCarPhotos] = useState([])

  useEffect(() => {
    const container = containerRef.current

    const delegate = props.delegate || '[data-fancybox]'
    const options = props.options || {}

    NativeFancybox.bind(container, delegate, options)

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  }, [props])

  useEffect(() => {
    if (props.vehicleImages) {
      const { ...Vehicleimages } = props.vehicleImages

      setCarPhotos(Object.values(Vehicleimages))
    }
  }, [props])

  if (!props.vehicleImages) {
    return (
      <div className='img-grid grid grid-cols-2 sm:grid-cols-4 sm:gap-4 gap-3 m-0 p-0 w-full '>
        {Array.from({ length: 7 }).map((_, index) => {
          return (
            <div
              className={`grid-img_block img landscape rounded-xl border-[1px] border-solid border-gray border-opacity-10 overflow-hidden ${
                index > 6 && 'hidden'
              }`}
              key={index}
            >
              <img
                src={placeholderCar}
                className='max-w-full h-auto block mx-auto'
                width='370'
                height='210'
                alt='Img'
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className='img-grid grid grid-cols-2 sm:grid-cols-3 sm:gap-4 gap-3 m-0 p-0 w-full '
      ref={containerRef}
      // eslint-disable-next-line react/no-unknown-property
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >
      {vehiclePhotos.length > 0 ? (
        vehiclePhotos?.map((img, index) => {
          return (
            <div
              data-fancybox='gallery'
              href={img}
              key={index}
              className={`grid-img_block img landscape rounded-xl border-[1px] border-solid border-gray border-opacity-10 overflow-hidden ${
                index > 5 && '!hidden'
              }`}
            >
              {index >= 5 ? (
                <div className='cursor-pointer absolute top-0 left-0 w-full h-full'>
                  <div className='bg-gray bg-opacity-70 title title-white absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-[1]'>
                    <h5 className='subHeading-5'>View all</h5>
                  </div>
                  <img
                    src={img}
                    className='w-full h-full object-cover rounded-xl'
                    alt='img'
                    onError={(e) => vehicleBrokenImage(e)}
                  />
                </div>
              ) : (
                <img
                  src={img}
                  className='max-w-full h-auto block mx-auto'
                  width='370'
                  height='210'
                  alt='img'
                  onError={(e) => vehicleBrokenImage(e)}
                />
              )}
            </div>
          )
        })
      ) : (
        <div
          data-fancybox='gallery'
          href={placeholderCar}
          className={`grid-img_block img landscape rounded-xl border-[1px] border-solid border-gray border-opacity-10 overflow-hidden`}
        >
          <img
            src={placeholderCar}
            className='max-w-full h-auto block mx-auto'
            width='370'
            height='210'
          />
        </div>
      )}
    </div>
  )
}

Fancybox.propTypes = {
  delegate: PropTypes.string,
  options: PropTypes.object,
  vehicleImages: PropTypes.object,
}

export default Fancybox
