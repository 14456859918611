import React, { Fragment } from 'react'
import Loader from '../../Loader'
import PropTypes from 'prop-types'

const CustomLoadingSection = ({ isLoading, children }) => {
  return (
    <Fragment>
      {isLoading ? <Loader /> : <Fragment> {children}</Fragment>}
    </Fragment>
  )
}

CustomLoadingSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default CustomLoadingSection
