import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// utils
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

import BuyerFinancialDetails from './BuyerFinancialDetails'
import SellerFinancialDetails from './SellerFinancialDetails'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { useSearchParams } from 'react-router-dom'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

const FinancialDetails = ({ setStep }) => {
  const [searchParams] = useSearchParams()
  const user = searchParams.get('user')
  const [userDetails, setUserDetails] = useState()
  const [userFinancialDetails, setUserFinancialDetails] = useState() //eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/user/detail/${user}`,
      )
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        if (
          response.data.userdetails.is_financial_details_completed &&
          response.data.userdetails.user_financial_details
        ) {
          setUserFinancialDetails(
            response.data.userdetails?.user_financial_details,
          )
        }
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      {userDetails?.role === 'buyer' ? (
        <BuyerFinancialDetails setStep={setStep} />
      ) : (
        <SellerFinancialDetails setStep={setStep} />
      )}
    </CustomLoadingSection>
  )
}

FinancialDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default FinancialDetails
