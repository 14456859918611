import React from 'react'
import { Link } from 'react-router-dom'

import { Icons } from '../Icons'

const { RiMenu2Fill } = Icons

import DropdownUser from './DropdownUser'
import DropdownNotification from './DropdownNotification'

// assets
import Logo from '../../assets/logo/revvit-logo.png'
import PropTypes from 'prop-types'

const Header = (props) => {
  const { setSidebarOpen, sidebarOpen } = props

  return (
    <header className='sticky top-0 z-[999] w-full h-[67px] flex bg-gray drop-shadow-1 border-b border-light-gray'>
      <div className='h-[67px] flex flex-grow items-center lg:justify-end justify-between shadow-2 py-4 px-4 md:px-6 2xl:px-11'>
        <div className='flex items-center gap-4 lg:hidden'>
          <button
            aria-controls='sidebar'
            onClick={(e) => {
              e.stopPropagation()
              setSidebarOpen(!sidebarOpen)
            }}
            className='z-[99999] block lg:hidden'
          >
            <RiMenu2Fill className='w-6 h-6' />
          </button>

          <Link className='block flex-shrink-0 lg:hidden' to='/'>
            <img src={Logo} alt='Logo' className='w-28 h-8' />
          </Link>
        </div>
        <div className='flex items-center gap-2'>
          <DropdownNotification />
          <DropdownUser />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
}

export default Header
