import { Link } from 'react-router-dom'
import React from 'react'

import Logo from '../../assets/logo/revvit-logo.png'
import not from '../../assets/404/404.gif'

import { Icons } from '../../components/Icons'

const { GoArrowLeft } = Icons

const PageNotFound = () => {
  return (
    <div className='w-full h-screen flex flex-col items-center justify-center bg-white'>
      <div className='p-10 rounded-xl flex flex-col items-center justify-center gap-5 md:max-w-screen-md w-full'>
        <div className='revvit-logo-wrapper'>
          <Link to='/'>
            <img src={Logo} alt='Logo' className='login-form-logo block h-11' />
          </Link>
        </div>
        <div className='flex flex-col items-center justify-center w-full p-0 m-0 gap-5'>
          <img src={not} alt='404' className='max-w-full h-auto block' />
          <div className='text-4xl font-extrabold text-gray'>
            <h1>Oops! Page Not Found.</h1>
          </div>
        </div>
        <div className='btn-custom mt-5'>
          <Link to='/' className='btn btn-dark-ico'>
            <GoArrowLeft />
            Go Back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
