import React from 'react'
import { ErrorMessage } from 'formik'
import Select from 'react-select'
import PropTypes from 'prop-types'

const CustomSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  onChange,
  placeholder,
  required,
  label,
  className,
  ParentComponentEvent,

  ...props
}) => {
  const onChangeHandlaer = (event) => {
    setFieldValue(field.name, event?.value ?? null)
    if (onChange) {
      onChange(event)
    }
  }

  const onChangeHandlaerForCustom = (event) => {
    ParentComponentEvent(event)
  }

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF',
      color: '#E60A54',
      borderRadius: '6px',
      borderColor: 'rgba(59,59,59,0.4)',
      boxShadow: state.isFocused ? null : null,
      height: '46px',
      minHeight: '32px',
      fontSize: '14px',
      fontWeight: '400',
      '&:hover': {
        borderColor: state.isFocused ? '#E60A54' : '#E60A54',
      },
      '@media (max-width: 639px)': {
        height: '38px',
        fontSize: '14px',
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: '14px',
        fontWeight: 400,
        color: '#000',
        opacity: '0.4',
      }
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderBottom: '0.1px solid #bfbfbf',
        borderRadius: 0,
        cursor: 'pointer',
        color: isFocused ? '#FFF' : '#000',
        backgroundColor: isDisabled
          ? '#E60A54'
          : isSelected
            ? '#E60A54'
            : isFocused
              ? '#E60A54'
              : '#FFFFFF',
        '@media (max-width: 639px)': {
          fontSize: '13px',
        },
      }
    },
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    singleValue: (styles) => ({ ...styles, color: '#000' }),
  }

  return (
    <div className={className}>
      {label && (
        <h5 className='flex items-center gap-1 text-base font-semibold text-gray mb-3'>
          {label}
          {required && <span className='text-danger'>*</span>}
        </h5>
      )}
      <Select
        {...field}
        {...props}
        styles={colourStyles}
        placeholder={placeholder}
        classNamePrefix='select'
        onChange={
          field?.name !== 'Province'
            ? (e) => onChangeHandlaer(e)
            : (e) => onChangeHandlaerForCustom(e)
        }
        className={`react-select text-sm font-normal ${
          !!touched[field.name] && !!errors[field.name]
        } ${touched[field.name] && !errors[field.name]} `}
      />
      <ErrorMessage
        name={field.name}
        component='div'
        className='text-xs font-normal text-danger'
      />
    </div>
  )
}

CustomSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  ParentComponentEvent: PropTypes.func,
}

export default CustomSelect
