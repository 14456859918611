// md
import {
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  MdOutlineCreate,
  MdOutlineKeyboardBackspace,
  MdChat,
  MdOutlineHome,
  MdDirectionsCar,
  MdArrowRightAlt,
  MdOutlineClose,
  MdOutlineCheck,
  MdDeleteOutline,
  MdDelete,
  MdAdd,
} from 'react-icons/md'

// ti
import { TiMediaPlayOutline, TiArrowDown } from 'react-icons/ti'

// io5
import {
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  IoCloseOutline,
  IoDocumentTextSharp,
  IoInformationCircleOutline,
} from 'react-icons/io5'

// pi
import { PiCylinderThin, PiSteeringWheel } from 'react-icons/pi'

// lu
import { LuCalendarDays, LuFuel } from 'react-icons/lu'

// tb
import { TbEngine } from 'react-icons/tb'

// vsc
import { VscTypeHierarchy } from 'react-icons/vsc'

// bi
import { BiSolidColorFill } from 'react-icons/bi'

// gi
import { GiCarDoor, GiGearStickPattern } from 'react-icons/gi'

// gr
import { GrDocumentText } from 'react-icons/gr'

//fi
import {
  FiSearch,
  FiChevronDown,
  FiTrash,
  FiBell,
  FiChevronUp,
  FiSettings,
  FiUpload,
} from 'react-icons/fi'

// ai
import { AiOutlineLogout, AiFillNotification } from 'react-icons/ai'

// ri
import { RiMenu2Fill } from 'react-icons/ri'

// bs
import { BsThreeDotsVertical, BsFillCircleFill } from 'react-icons/bs'

// fa
import {
  FaRegEdit,
  FaRegEye,
  FaSignInAlt,
  FaCar,
  FaUser,
  FaRegUserCircle,
  FaUserCog,
  FaCheck,
  FaRegEyeSlash,
} from 'react-icons/fa'

// go
import { GoArrowLeft } from 'react-icons/go'

// ci
import { CiWarning, CiUser } from 'react-icons/ci'

export const Icons = {
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  BiSolidColorFill,
  GiCarDoor,
  GiGearStickPattern,
  GrDocumentText,
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  PiCylinderThin,
  PiSteeringWheel,
  TbEngine,
  VscTypeHierarchy,
  LuCalendarDays,
  LuFuel,
  IoCloseOutline,
  CiWarning,
  FiSearch,
  MdOutlineCreate,
  BsThreeDotsVertical,
  MdOutlineKeyboardBackspace,
  FiChevronDown,
  FiTrash,
  FaRegEdit,
  FaRegEye,
  FaSignInAlt,
  MdChat,
  FiBell,
  FaCar,
  FaUser,
  IoDocumentTextSharp,
  FaRegUserCircle,
  CiUser,
  AiOutlineLogout,
  FaUserCog,
  BsFillCircleFill,
  MdOutlineHome,
  MdDirectionsCar,
  AiFillNotification,
  FiChevronUp,
  FiSettings,
  RiMenu2Fill,
  FaCheck,
  FaRegEyeSlash,
  GoArrowLeft,
  TiMediaPlayOutline,
  IoInformationCircleOutline,
  MdArrowRightAlt,
  MdOutlineClose,
  TiArrowDown,
  MdOutlineCheck,
  FiUpload,
  MdDeleteOutline,
  MdDelete,
  MdAdd,
}
