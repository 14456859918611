import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const SidebarLinkGroup = ({ children, activeCondition, closeMenu }) => {
  const [open, setOpen] = useState(activeCondition)

  const handleClick = () => {
    setOpen((prevOpen) => {
      const newOpen = !prevOpen
      if (!newOpen) {
        closeMenu()
      }
      return newOpen
    })
  }

  useEffect(() => {
    if (!activeCondition) {
      setOpen(false)
    }
  }, [activeCondition])

  return <li>{children(handleClick, open)}</li>
}

SidebarLinkGroup.propTypes = {
  children: PropTypes.func.isRequired,
  activeCondition: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
}

export default SidebarLinkGroup
