import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

// assets
import Logo from '../../assets/logo/sidebar-logo.png'

import SidebarLinkGroup from './SidebarLinkGroup'

import { Icons } from '../Icons'

const {
  FaUserCog,
  BsFillCircleFill,
  MdOutlineHome,
  MdDirectionsCar,
  AiFillNotification,
  FiChevronDown,
  FiChevronUp,
  FiSettings,
} = Icons

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation()
  const { pathname } = location

  const trigger = useRef(null)
  const sidebar = useRef(null)

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  )
  const [openMenu, setOpenMenu] = useState(null) //eslint-disable-line no-unused-vars
  const closeMenu = () => {
    setOpenMenu(null)
  }
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString())
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded')
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])

  const vehicleMenuLinks = [
    {
      to: '/vehicle/all',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'All',
    },
    {
      to: '/vehicle/draft',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Draft',
    },
    {
      to: '/vehicle/parking',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Parking',
    },
    {
      to: '/vehicle/live',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Live',
    },
    {
      to: '/vehicle/schedule',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Scheduled',
    },
    {
      to: '/vehicle/pending',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Pending',
    },
    {
      to: '/vehicle/closed',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Closed',
    },
    {
      to: '/vehicle/awarded',
      icon: <BsFillCircleFill className='text-[10px]' />,
      label: 'Sold',
    },
  ]
  const userMenuLinks = [
    { to: '/user/all', label: 'All', icon: BsFillCircleFill },
    { to: '/user/pending', label: 'Pending', icon: BsFillCircleFill },
    {
      to: '/user/role-change-requests',
      label: 'Requests to switch roles',
      icon: BsFillCircleFill,
    },
    { to: '/user/seller', label: 'Sellers', icon: BsFillCircleFill },
    { to: '/user/buyer', label: 'Buyers', icon: BsFillCircleFill },
  ]
  const settingMenuLinks = [
    {
      to: '/setting/offer-time',
      label: 'Offer Timeframe',
      icon: BsFillCircleFill,
    },

    {
      to: '/setting/tax/all',
      label: 'Tax Configuration',
      icon: BsFillCircleFill,
    },

    {
      to: '/setting/template/all',
      label: 'Email Template',
      icon: BsFillCircleFill,
    },
  ]

  return (
    <aside
      ref={sidebar}
      className={`absolute lg:static left-0 top-0 z-[99] flex h-screen w-[300px] flex-col overflow-y-hidden bg-gray duration-300 ease-linear lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className='flex items-center justify-center gap-2 px-5 py-4'>
        <NavLink to='/'>
          <img src={Logo} alt='Logo' className='w-[120px] h-[35px]' />
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls='sidebar'
          aria-expanded={sidebarOpen}
          className='hidden'
        >
          NON
        </button>
      </div>

      <div className='no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear px-5 lg:px-5'>
        <nav className='border-t border-white border-opacity-10'>
          <div className='pt-8 pb-4'>
            <ul className='flex flex-col gap-3'>
              <li>
                <NavLink
                  to='/'
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  className={`group relative text-slate-100 flex items-center gap-2.5 rounded-3xl py-2 px-4 font-medium hover:text-white duration-300 ease-in-out hover:bg-primary ${
                    pathname === '/' && 'bg-primary !text-white'
                  }`}
                >
                  <MdOutlineHome className='text-xl' />
                  Home
                </NavLink>
              </li>
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/vehicle' || pathname.includes('vehicle')
                }
                closeMenu={closeMenu}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative text-slate-100 flex justify-between items-center gap-2.5 rounded-3xl py-2 px-4 font-medium hover:text-white duration-300 ease-in-out hover:bg-primary ${
                          pathname.includes('/vehicle') &&
                          'bg-primary !text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <div className='flex items-center gap-3'>
                          <MdDirectionsCar className='text-xl' />
                          Vehicles
                        </div>
                        {open ? (
                          <FiChevronUp className='text-xl duration-300' />
                        ) : (
                          <FiChevronDown className='text-xl duration-300' />
                        )}
                      </NavLink>
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5 flex flex-col gap-2 pl-6'>
                          {vehicleMenuLinks.map((navLink, index) => (
                            <li key={index}>
                              <NavLink
                                to={navLink.to}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                                className={({ isActive }) =>
                                  'group relative text-slate-100 flex items-center gap-2.5 rounded-3xl py-1 px-3 text-base font-normal hover:text-white duration-300 ease-in-out hover:bg-primary ' +
                                  (isActive && '!text-white bg-primary')
                                }
                              >
                                {navLink.icon}
                                {navLink.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/user' || pathname.includes('user')
                }
                closeMenu={closeMenu}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative text-slate-100 flex justify-between items-center gap-2.5 rounded-3xl py-2 px-4 font-medium hover:text-white duration-300 ease-in-out hover:bg-primary ${
                          pathname.includes('/user') && 'bg-primary !text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <div className='flex items-center gap-3'>
                          <FaUserCog className='text-xl' />
                          Users
                        </div>
                        {open ? (
                          <FiChevronUp className='text-xl duration-300' />
                        ) : (
                          <FiChevronDown className='text-xl duration-300' />
                        )}
                      </NavLink>
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5 flex flex-col gap-2 pl-6'>
                          {userMenuLinks.map((userLink, index) => (
                            <li key={index}>
                              <NavLink
                                to={userLink.to}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                                className={({ isActive }) =>
                                  'group relative text-slate-100 flex items-center gap-2.5 rounded-3xl py-1 px-3 text-base font-normal hover:text-white duration-300 ease-in-out hover:bg-primary ' +
                                  (isActive && '!text-white bg-primary')
                                }
                              >
                                <userLink.icon className='text-[10px]' />
                                {userLink.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>
              <SidebarLinkGroup
                activeCondition={
                  pathname === '/setting' || pathname.includes('setting')
                }
                closeMenu={closeMenu}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <NavLink
                        to='#'
                        className={`group relative text-slate-100 flex justify-between items-center gap-2.5 rounded-3xl py-2 px-4 font-medium hover:text-white duration-300 ease-in-out hover:bg-primary ${
                          pathname.includes('/setting') &&
                          'bg-primary !text-white'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true)
                        }}
                      >
                        <div className='flex items-center gap-3'>
                          <FiSettings className='text-xl' />
                          Settings
                        </div>
                        {open ? (
                          <FiChevronUp className='text-xl duration-300' />
                        ) : (
                          <FiChevronDown className='text-xl duration-300' />
                        )}
                      </NavLink>
                      <div
                        className={`translate transform overflow-hidden ${
                          !open && 'hidden'
                        }`}
                      >
                        <ul className='mt-4 mb-5 flex flex-col gap-2 pl-6'>
                          {settingMenuLinks.map((settingLink, index) => (
                            <li key={index}>
                              <NavLink
                                to={settingLink.to}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                                className={({ isActive }) =>
                                  'group relative text-slate-100 flex items-center gap-2.5 rounded-3xl py-1 px-3 text-base font-normal hover:text-white duration-300 ease-in-out hover:bg-primary ' +
                                  (isActive && '!text-white bg-primary')
                                }
                              >
                                <settingLink.icon className='text-[10px]' />
                                {settingLink.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </React.Fragment>
                  )
                }}
              </SidebarLinkGroup>

              <li>
                <NavLink
                  to='/announcement/all'
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  className={`group relative text-slate-100 flex items-center gap-2.5 rounded-3xl py-2 px-4 font-medium hover:text-white duration-300 ease-in-out hover:bg-primary ${
                    pathname === '/announcement' && 'bg-primary !text-white'
                  }`}
                >
                  <AiFillNotification className='text-xl' />
                  Announcements
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
}

export default Sidebar
