import React, { Fragment, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomInput from '../../../../custom/CustomInput'
import CustomSelect from '../../../../custom/CustomSelect'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomDeleteUpdateModal from '../../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { FiTrash, FaRegEye } = Icons

const provinceList = [
  { value: 'Alberta', label: 'Alberta' },
  { value: 'British Columbia', label: 'British Columbia' },
  { value: 'Manitoba', label: 'Manitoba' },
  { value: 'New Brunswick', label: 'New Brunswick' },
  { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
  { value: 'Nova Scotia', label: 'Nova Scotia' },
  { value: 'Northwest Territories', label: 'Northwest Territories' },
  { value: 'Nunavut', label: 'Nunavut' },
  { value: 'Ontario', label: 'Ontario' },
  { value: 'Prince Edward Island', label: 'Prince Edward Island' },
  { value: 'Quebec', label: 'Quebec' },
  { value: 'Saskatchewan', label: 'Saskatchewan' },
  { value: 'Yukon', label: 'Yukon' },
]

const validationSchema = Yup.object().shape({
  state_code: Yup.string().required('Province is required.'),
  tax_name: Yup.string().trim().required('Tax Name is required.'),
  rate: Yup.number()
    .required('Rate is required.')
    .min(1, 'Rate must be a number between 1 and 100.')
    .max(100, 'Rate must be a number between 1 and 100.'),
  priority: Yup.string().trim().required('Priority is required.'),
})

const Edit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const [isloading, setisloading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(`/tax/details/${id}`)
      if (response.data.success) {
        formik.setValues({
          ...formik.values,
          ...response?.data?.taxDetails,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [id])

  const handleSubmit = async (values) => {
    setIsLoading(true)

    try {
      const response = await axiosInterceptorInstance.put(
        `/tax/edit/${id}`,
        values,
      )

      setIsLoading(false)
      if (response.data.success) {
        CustomToast({
          message: response.data.message || 'Tax updated successfully!',
          type: 'success',
        })
        navigate('/setting/tax/all')
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      state_code: '',
      tax_name: '',
      rate: '',
      priority: '',
    },
    validateOnBlur: false,
  })

  const { isSubmitting } = formik

  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/tax/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'User deleted successfully',
        type: 'success',
      })

      setisloading(false)

      setdeleteuserModel('')
      navigate('/setting/tax/all')
    } catch (error) {
      setisloading(false)

      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }

  return (
    <Fragment>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='Edit' />

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className='w-full'>
            <div className='flex flex-col w-full gap-10'>
              <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
                <div className='flex justify-end'>
                  <CustomToolTip label='Edit'>
                    <NavLink
                      to={`/setting/tax/view/${id}`}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                      title='Edit'
                    >
                      <FaRegEye size={30} />
                    </NavLink>
                  </CustomToolTip>
                  <CustomToolTip label='Delete'>
                    <button
                      type='button'
                      onClick={() => deleteModalhandler(id)}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                    >
                      <FiTrash />
                    </button>
                  </CustomToolTip>
                </div>
                <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                  <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='tax_name'
                      label='Tax Name'
                      placeholder='Tax Name'
                      component={CustomInput}
                      required={true}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    />
                  </div>
                  <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='rate'
                      type='number'
                      label='Rate (%)'
                      placeholder='Rate (%)'
                      component={CustomInput}
                      required={true}
                      inputMode='numeric'
                      onKeyPress={(e) => {
                        const keyCode = e.keyCode || e.which
                        const keyValue = String.fromCharCode(keyCode)
                        const isValid = /\d|\./.test(keyValue)
                        if (!isValid) {
                          e.preventDefault()
                        }
                      }}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    />
                  </div>
                  <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='state_code'
                      label='Province'
                      placeholder='Select Province'
                      component={CustomSelect}
                      options={provinceList}
                      required={true}
                      value={provinceList.find(
                        (option) => option.value === formik.values.state_code,
                      )}
                    />
                  </div>
                  <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='priority'
                      type='number'
                      label='Priority'
                      placeholder='Priority'
                      component={CustomInput}
                      required={true}
                      inputMode='numeric'
                      onKeyPress={(e) => {
                        const keyCode = e.keyCode || e.which
                        const keyValue = String.fromCharCode(keyCode)
                        if (/\D/.test(keyValue)) {
                          e.preventDefault()
                        }
                      }}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    />
                  </div>
                </div>

                <button
                  type='submit'
                  className='btn btn-primary-ico w-20 ml-auto'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>

            {deleteuserModel && (
              <CustomDeleteUpdateModal
                closeModal={closeModal}
                isloading={isloading}
                onSubmit={submitdeletehandler}
                message={
                  'Are you sure you want to delete this tax configuration?'
                }
              />
            )}
          </form>
        </FormikProvider>
      </CustomLoadingSection>
    </Fragment>
  )
}

export default Edit
