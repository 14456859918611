import React from 'react'
import DataTable from 'react-data-table-component'

const CustomNoDataComponent = ({
  columns,
  noSearchResultMessage,
  noUserMessage,
  loading,
  searchText,
  List,
  data,
}) => {
  const noDataComponent = loading ? (
    <div className='w-full flex justify-center items-center'>
      <div className='h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent'></div>
    </div>
  ) : (
    <div className='text-center w-full'>
      <DataTable
        columns={columns}
        data={data}
        noDataComponent={
          <table className='table-auto m-5'>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={columns.length} className='text-center'>
                  {searchText && List.length === 0
                    ? noSearchResultMessage
                    : noUserMessage}
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </div>
  )

  return noDataComponent
}

export default CustomNoDataComponent
