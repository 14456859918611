import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomDeleteUpdateModal from '../../../../components/Custom/CustomDeleteUpdateModal'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { FaRegEdit, FiTrash } = Icons

const View = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [announcementDetails, setAnnouncementDetails] = useState(null)

  const [isloading, setisloading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/announcements/details/${id}`,
      )
      if (response.data.success) {
        setAnnouncementDetails(response.data.announcementDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (dateString) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }
    return new Date(dateString).toLocaleString('en-GB', options)
  }
  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/announcements/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'Announcement deleted successfully',
        type: 'success',
      })

      setisloading(false)

      setdeleteuserModel('')
      navigate('/announcement/all')
    } catch (error) {
      setisloading(false)

      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }
  return (
    <div>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='View' />

        <section className='acc_user'>
          <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5'>
            <div className='flex justify-end'>
              <CustomToolTip label='Edit Announcement'>
                <NavLink
                  to={`/announcement/edit/${id}`}
                  className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                >
                  <FaRegEdit size={30} />
                </NavLink>
              </CustomToolTip>
              <CustomToolTip label='Delete Announcement'>
                <button
                  onClick={() => deleteModalhandler(id)}
                  className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                >
                  <FiTrash />
                </button>
              </CustomToolTip>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-5'>
              <div className='w-full max-w-[calc(50%_-_10px)]'>
                <h5 className='text-gray text-sm font-500 mb-2'>Username</h5>

                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {announcementDetails?.created_by?.username}
                </div>
              </div>

              <div className='max-lg:w-full lg:flex-auto'>
                <h5 className='text-gray text-sm font-500 mb-2'>Created At</h5>

                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {formatDate(announcementDetails?.created_at)}
                </div>
              </div>
              <div className='max-lg:w-full lg:flex-auto'>
                <h5 className='text-gray text-sm font-500 mb-2'>Send To</h5>

                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {announcementDetails?.target_role}
                </div>
              </div>
              <div className='w-full'>
                <h5 className='text-gray text-sm font-500 mb-2'>Message</h5>
                <div
                  className='txt_area bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0 overflow-y-auto max-h-40'
                  dangerouslySetInnerHTML={{
                    __html: announcementDetails?.message,
                  }}
                  id='announcement_message_display'
                  readOnly
                />
              </div>

              <div className='w-full'>
                <h5 className='text-gray text-sm font-500 mb-2'>Status</h5>

                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {announcementDetails?.status}
                </div>
              </div>

              {deleteuserModel && (
                <CustomDeleteUpdateModal
                  closeModal={closeModal}
                  isloading={isloading}
                  onSubmit={submitdeletehandler}
                  message={'Are you sure you want to delete this announcement?'}
                />
              )}
            </div>
          </div>
        </section>
      </CustomLoadingSection>
    </div>
  )
}

export default View
