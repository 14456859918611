import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import io from 'socket.io-client'
import moment from 'moment'

import configManager from '../../config/configManager'

import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../utils/axios/handleRequestError'

import Loader from '../../components/Loader'
import InvitationNotificationYear from '../../utils/notificationYearFormatter/InvitationNotificationYear'
import CustomLoadingSection from '../../components/Custom/CustomLoadingSection'

import { Icons } from '../../components/Icons'

const { IoDocumentTextSharp, FaCar, FaUser, MdChat } = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const Notification = () => {
  const navigate = useNavigate()

  const [isNotificatinLoading, setIsNotificatinLoading] = useState(true)
  const [notificationList, setNotificationList] = useState([])

  const fetchNotificationList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/notification/list`)
      if (response.data.success) {
        setNotificationList(response.data.notifications)
        setIsNotificatinLoading(false)
      }
    } catch (error) {
      setIsNotificatinLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchNotificationList()
  }, [])

  useEffect(() => {
    socket.on('notification_received', () => {
      fetchNotificationList()
    })
  }, [])

  useEffect(() => {
    socket.on('admin_all_notifications_read', () => {
      fetchNotificationList()
    })
  }, [])

  useEffect(() => {
    socket.on('admin_single_notifications_read', () => {
      fetchNotificationList()
    })
  }, [])

  const handleClick = async (item) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/read/${item._id}`,
      )
      if (response.data.success) {
        if (item.type === 'user') {
          navigate(`/user/view/${item?.user_id?._id}`)
        }
        if (item.type === 'vehicle') {
          navigate(`/vehicle/view/${item?.vehicle_id?._id}`)
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const makeAllNotificationAsRead = async () => {
    try {
      await axiosInterceptorInstance.post('/notification/all/read')
    } catch (error) {
      handleRequestError(error)
    }
  }

  return (
    <CustomLoadingSection isLoading={isNotificatinLoading}>
      <div className='flex flex-col items-start justify-start w-full max-w-xl p-0 m-0 mx-auto'>
        <div className='sticky top-0 flex flex-wrap items-end justify-between w-full px-5 py-5 border-b border-gray/10'>
          <h5 className='text-lg font-medium text-gray'>Notifications</h5>
          {notificationList.length > 0 && (
            <div
              className='text-sm font-normal cursor-pointer text-primary'
              onClick={() => makeAllNotificationAsRead()}
            >
              Mark all as read
            </div>
          )}
        </div>

        {isNotificatinLoading ? (
          <Loader />
        ) : (
          <ul className='flex flex-col w-full py-5 gap-y-4'>
            {notificationList.length > 0 ? (
              notificationList.map((item, key) => (
                <li
                  key={key}
                  className={`${
                    item.is_admin_read === false
                      ? 'bg-primary bg-opacity-10 border-primary/10'
                      : 'bg-gray/5 border-gray/10'
                  } rounded-lg cursor-pointer border border-gray/10`}
                  onClick={() => handleClick(item)}
                >
                  <div className='flex flex-wrap items-center group/list'>
                    <p className='px-4 py-2 ml-auto text-xs font-light text-right text-gray whitespace-nowrap'>
                      {moment(item?.created_at).format('lll')}
                    </p>
                    <div className='flex flex-wrap items-center justify-start w-full gap-4 p-0 px-8 pt-3 pb-3 m-0 border-0 border-t border-gray/5'>
                      <div>
                        <div className='flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-primary '>
                          {item.type === 'vehicle' && <FaCar />}
                          {item.type === 'user' && <FaUser />}
                          {item.type === 'document' && <IoDocumentTextSharp />}
                          {item.type === 'chat' && <MdChat />}
                          {item.type === 'vehicle_invite' && <FaUser />}
                        </div>
                      </div>

                      <div className='flex flex-col items-start justify-start flex-grow gap-1 max-w-[calc(100%_-_48px)]'>
                        <div>
                          <InvitationNotificationYear message={item.message} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p className='text-center text-gray'>No notifications found.</p>
            )}
          </ul>
        )}
      </div>
    </CustomLoadingSection>
  )
}

export default Notification
