import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomVehicleTable from '../../../components/Custom/CustomVehicleTable'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { columns } from './columns'

const AllVehicle = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [vehicleList, setvehicleList] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const [totalRecords, setTotalRecords] = useState(0)
  const [isloading, setisloading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const fetchData = async () => {
    try {
      setSearchLoading(true)
      const response = await axiosInterceptorInstance.get(
        `/vehicle/list?limit=${pageSize}&page=${currentPage}&search=${searchText}`,
      )
      setvehicleList(response.data.vehicleDetailsList)
      setTotalRecords(response.data.totalRecords)
      setTotalPages(response.data.totalPages)
      setCurrentPage(response.data.currentPage)
      setLoading(false)
      setSearchLoading(false)
    } catch (error) {
      setLoading(false)
      setSearchLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [pageSize, currentPage, searchText])

  const handleSearch = async () => {
    setSearchLoading(true)
    fetchData()
  }
  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `vehicle/seller/vehicle-details/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'Vehicle deleted successfully',
        type: 'success',
      })
      fetchData()
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
    } catch (error) {
      setisloading(false)
      setLoading(false)
      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handlePerPageChange = (newPerPage, page) => {
    setPageSize(newPerPage)
    setCurrentPage(page)
  }

  const handleRowClick = (row) => {
    navigate(`/vehicle/view/${row._id}`)
  }

  return (
    <CustomLoadingSection isLoading={loading}>
      <CustomTitle
        Serchbox={{
          searchText,
          setSearchText,
          handleSearch,
          placeholder: 'Search Vehicles',
        }}
        customTitle='Vehicles'
        createLink={'/vin/decoder'}
        showCreateLink={true}
      />

      <CustomVehicleTable
        loading={loading}
        columns={columns}
        vehicleList={vehicleList}
        searchText={searchText}
        totalPages={totalPages}
        currentPage={currentPage}
        pageSize={pageSize}
        searchLoading={searchLoading}
        deleteuserModel={deleteuserModel}
        handlePageChange={handlePageChange}
        handlePerPageChange={handlePerPageChange}
        handleRowClick={handleRowClick}
        deleteModalhandler={deleteModalhandler}
        submitdeletehandler={submitdeletehandler}
        isloading={isloading}
        closeModal={closeModal}
        noSearchResultMessage={'Your search did not match any results.'}
        noUserMessage={'There are no vehicle listings available.'}
        status='all'
        totalRecords={totalRecords}
      />
    </CustomLoadingSection>
  )
}

export default AllVehicle
