import React, { Fragment, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'

// custom components
import CustomInput from '../../../custom/CustomInput'
import CustomToast from '../../../components/Custom/CustomToast'

import { Icons } from '../../../components/Icons'

const { MdOutlineCheck, FiUpload } = Icons

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
})

const PersonalDetails = ({ setStep }) => {
  const { id } = useParams()
  const [userDetails, setUserDetails] = useState()

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/user/detail/${id}`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        formik.setValues({
          ...formik.values,
          ...response.data?.userdetails,
        })
        const fieldsToSet = {
          ...response.data?.userdetails,
        }

        formik.setFieldValue(
          'profile_picture_preview',
          fieldsToSet?.profile_picture || '',
        )
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  //eslint-disable-next-line no-unused-vars
  const handleSubmit = async (values, { resetForm }) => {
    if (userDetails._id) {
      try {
        const formData = new FormData()
        Object.keys(values).forEach((key) => {
          if (
            typeof values[key] === 'object' &&
            !(values[key] instanceof File)
          ) {
            formData.append(key, JSON.stringify(values[key]))
          } else {
            formData.append(key, values[key])
          }
        })

        const response = await axiosInterceptorInstance.put(
          `/user/personal/update/${userDetails._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          setStep(2)
        }
      } catch (error) {
        handleRequestError(error)
      }
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      profile_picture: '',
      profile_picture_preview: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
  })

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0]
    formik.setFieldValue('profile_picture', file)

    if (file) {
      const previewUrl = URL.createObjectURL(file)
      formik.setFieldValue('profile_picture_preview', previewUrl)
    }
  }
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className='w-full'>
        <div className='flex flex-col w-full gap-10'>
          <div>
            <div className='title title-primary text-left mb-3'>
              <h2 className='heading-2'>Personal information</h2>
            </div>
            <div className='content text-left'>
              <p>Please provide your name, email address, and phone number.</p>
            </div>
          </div>
          <ul className='max-sm:w-full flex-[0_0_auto] flex flex-wrap justify-start items-center'>
            <li className='inline-block'>
              <label className='flex flex-col items-start justify-start w-full last:border-0 border-b border-gray border-opacity-10 pb-3 last:pb-0 mb:pb-0 md:border-0'>
                <div className='w-24 h-24 rounded-full bg-gray bg-opacity-5 cursor-pointer border border-dashed border-gray border-opacity-20 relative overflow-hidden'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    name='Profile'
                    onChange={(event) => handleImageChange(event)}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.profile_picture_preview ? (
                    <img
                      src={formik.values.profile_picture_preview}
                      alt='Preview'
                      className='w-full h-full object-cover absolute top-0 left-0'
                      onError={(e) => userBrokenImages(e)}
                    />
                  ) : (
                    <div className='w-full h-full absolute top-0 flex items-center justify-center object-cover bg-light-gray text-primary text-3xl rounded-full p-0'>
                      {formik.values.first_name?.substring(0, 2).toUpperCase()}
                    </div>
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    <div className='flex flex-col items-center justify-center mx-auto mb-1 text-primary w-8 h-8 rounded-full bg-white bg-opacity-50 hover:bg-primary hover:text-white'>
                      <FiUpload />
                    </div>
                  </div>
                </div>
                {formik.touched.profile_picture &&
                  formik.errors.profile_picture && (
                    <div className='text-xs font-normal text-danger'>
                      {formik.errors.profile_picture}
                    </div>
                  )}
              </label>
            </li>
          </ul>
          <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
            <div className='w-full md:max-w-[calc(50%_-_10px)]'>
              <Field
                name='first_name'
                label='First Name'
                placeholder='First Name'
                component={CustomInput}
                required={true}
                className='form-field'
              />
            </div>
            <div className='w-full md:max-w-[calc(50%_-_10px)]'>
              <Field
                name='last_name'
                label='Last Name'
                placeholder='Last Name'
                component={CustomInput}
                required={true}
                className='form-field'
              />
            </div>
            <div className='w-full md:max-w-[calc(50%_-_10px)]'>
              <Field
                type='email'
                name='email'
                label='Email'
                placeholder='Email*'
                component={CustomInput}
                className='form-field !bg-light-gray/50'
                isDisabled={true}
              />
            </div>

            <div className='w-full md:max-w-[calc(50%_-_10px)]'>
              <Field
                type='text'
                name='phone'
                label='Phone Number'
                required
                placeholder='Phone Number'
                component={CustomInput}
                className='form-field'
              />
            </div>
            <div className='w-full md:max-w-[calc(50%_-_10px)]'>
              <Field
                type='text'
                name='auction_access_number'
                label='Auction Access Number'
                placeholder='Auction Access Number'
                component={CustomInput}
                required={true}
                className='form-field'
              />
            </div>

            <div className='flex items-center justify-center w-full gap-5 mt-10 flex-wrap md:items-center xs:justify-end'>
              <button
                type='submit'
                className='btn btn-primary-ico w-full xs:w-auto'
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <div className='h-5 w-5 mx-auto animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
                ) : (
                  <Fragment>
                    Save & Continue
                    <MdOutlineCheck />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

PersonalDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default PersonalDetails
