import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

// custom components
import CustomTitle from '../../../../components/Custom/CustomTitle'

// context
import { useStepContext } from '../../../../context/useStepContext'

const Add = () => {
  const [searchParams] = useSearchParams()

  const { step, steps } = useStepContext()

  const vehicle = searchParams.get('vehicle')
  const data = useSelector((store) => store.vehicle)

  return (
    <Fragment>
      <CustomTitle backBtn backBtncount={vehicle ? 2 : 1} />

      <div className='w-full flex flex-col large:flex-row justify-between items-center gap-8'>
        <div className='w-full flex flex-wrap items-center my-10'>
          <h2 className='text-xl large:text-2xl font-semibold text-gray border-r border-gray/30 pr-4 mr-4'>
            {data?.attributes?.year}&nbsp;
            {data?.attributes?.make}&nbsp;
            {data?.attributes?.model}
          </h2>
          <h5 className='subHeading-5 font-semibold text-gray/90'>
            VIN {data?.vin}
          </h5>
        </div>
      </div>

      <div className='add-tabs md:flex items-center lg:justify-start'>
        {steps.map((s) => (
          <div
            key={s.stepNumber}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-default ${
              step === s.stepNumber && 'active'
            }`}
          >
            {s.label}
          </div>
        ))}
      </div>

      <div className='border border-gray border-opacity-10 rounded-xl rounded-tl-none max-md:rounded-tr-none p-5 md:px-8 md:py-10'>
        {steps.map((s) =>
          step === s.stepNumber ? <s.component key={s.stepNumber} /> : null,
        )}
      </div>
    </Fragment>
  )
}

export default Add
