import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

// custom components
import CustomNoDataComponent from '../../../components/Custom/CustomNoDataComponent'
import CustomDeleteUpdateModal from '../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../CustomToolTip'

import { Icons } from '../../Icons'

const { FiChevronDown, FiTrash, FaRegEdit, FaRegEye, FaSignInAlt } = Icons

const CustomUserTable = ({
  loading,
  columns,
  userList,
  searchText,
  // totalPages,
  // currentPage,
  pageSize,
  searchLoading,
  updateuserModel,
  deleteuserModel,
  // handleSearch,
  handlePageChange,
  handlePerPageChange,
  handleRowClick,
  editModalhandler,
  deleteModalhandler,
  submitedithandler,
  submitdeletehandler,
  isloading,
  closeModal,
  noSearchResultMessage,
  noUserMessage,
  loginAsUserHandler,
  roleChangeRequestPage,
  totalRecords,
}) => {
  const data = userList?.map((data) => ({
    ...data,

    action: (
      <div className='flex flex-wrap gap-2 mt-1' role='group'>
        <CustomToolTip label='View'>
          <CustomToolTip label='Login'>
            <button
              onClick={() => loginAsUserHandler(data._id)}
              className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
            >
              <FaSignInAlt />
            </button>
          </CustomToolTip>
          <NavLink
            to={`/user/view/${data._id}`}
            className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
          >
            <FaRegEye />
          </NavLink>
        </CustomToolTip>
        <CustomToolTip label='Edit'>
          <NavLink
            to={`/user/edit/${data._id}`}
            className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
          >
            <FaRegEdit />
          </NavLink>
        </CustomToolTip>
        <CustomToolTip label='Delete'>
          <button
            onClick={() => deleteModalhandler(data._id)}
            className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
          >
            <FiTrash />
          </button>
        </CustomToolTip>
      </div>
    ),
    approve: (
      <button
        onClick={() => editModalhandler(data._id)}
        className='btn-primary-a'
      >
        {!roleChangeRequestPage
          ? !data.is_admin_verified
            ? 'Approve'
            : 'Reject'
          : data.switch_role_request
            ? 'Approve'
            : 'Reject'}
      </button>
    ),
  }))

  const noDataComponent = (
    <CustomNoDataComponent
      columns={columns}
      noSearchResultMessage={noSearchResultMessage}
      noUserMessage={noUserMessage}
      loading={loading}
      searchText={searchText}
      List={userList}
      data={data}
    />
  )

  return (
    <Fragment>
      {loading ? (
        <div className='w-full flex justify-center items-center'>
          <div className='h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent'></div>
        </div>
      ) : (
        <Fragment>
          <DataTable
            data={data}
            columns={columns}
            sortIcon={<FiChevronDown size={10} />}
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 60, 80, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerPageChange}
            highlightOnHover
            noDataComponent={noDataComponent}
            pointerOnHover
            onRowClicked={handleRowClick}
            noHeader
            persistTableHead={true}
            progressPending={searchLoading}
            progressComponent={
              <div className='flex items-center justify-center py-5'>
                <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
              </div>
            }
          />
        </Fragment>
      )}

      {updateuserModel && (
        <CustomDeleteUpdateModal
          closeModal={closeModal}
          isloading={isloading}
          onSubmit={submitedithandler}
          message={
            roleChangeRequestPage
              ? userList.find((user) => user._id === updateuserModel)
                  ?.switch_role_request
                ? 'Do you want to approve the role switch request for this user?'
                : 'Do you want to disapprove the role switch request for this user?'
              : userList.find((user) => user._id === updateuserModel)
                    ?.is_admin_verified
                ? 'Do you want to disapprove this user?'
                : 'Do you want to approve this user?'
          }
        />
      )}
      {deleteuserModel && (
        <CustomDeleteUpdateModal
          closeModal={closeModal}
          isloading={isloading}
          onSubmit={submitdeletehandler}
          message={'Are you sure you want to delete this user?'}
        />
      )}
    </Fragment>
  )
}

CustomUserTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  searchLoading: PropTypes.bool,
  updateuserModel: PropTypes.string,
  deleteuserModel: PropTypes.string,
  handlePageChange: PropTypes.func.isRequired,
  handlePerPageChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  editModalhandler: PropTypes.func.isRequired,
  deleteModalhandler: PropTypes.func.isRequired,
  submitedithandler: PropTypes.func.isRequired,
  submitdeletehandler: PropTypes.func.isRequired,
  isloading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  noSearchResultMessage: PropTypes.string,
  noUserMessage: PropTypes.string,
  loginAsUserHandler: PropTypes.func,
  roleChangeRequestPage: PropTypes.bool,
  totalRecords: PropTypes.number.isRequired,
}

export default CustomUserTable
