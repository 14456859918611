import React, { useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

// custom inputs
import CustomInput from '../../../custom/CustomInput'

// assets
import VIcon from '../../../assets/icons/icon.png'

// utils
import { setToken } from '../../../utils/cookies/cookies'
import axiosAuthInstance from '../../../utils/axios/axiosAuthInstance'

const schema = Yup.object().shape({
  username: Yup.string().required('Please enter your email or username'),
  password: Yup.string().required('Please enter your password!'),
})

const Login = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosAuthInstance('/login', values)
      if (response.data.success) {
        setToken(response.data.token)
        setIsLoading(false)
        navigate('/')
      }
    } catch (error) {
      if (error.response.data.message) {
        setIsError(error.response.data.message)
      } else {
        setIsError('Server Error')
      }
      setIsLoading(false)
      resetForm()
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      username: '',
      password: '',
    },
  })

  const focusHandler = () => {
    isError && setIsError('')
  }

  return (
    <div className='login-root'>
      <div className='login-card-root'>
        <div className='revvit-logo-wrapper'>
          <img src={VIcon} alt='Logo' className='login-form-logo' />
          <h4>Welcome To Admin</h4>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className='form-field-wrapper'>
              <Field
                type='text'
                name='username'
                placeholder='Username or Email'
                component={CustomInput}
                className='form-field'
                focusHandler={focusHandler}
              />
              <Field
                type='password'
                name='password'
                placeholder='Password'
                component={CustomInput}
                className='form-field'
                focusHandler={focusHandler}
              />
              {isError && (
                <div className='text-center text-red'> {isError}</div>
              )}
            </div>
            <div className='btn-wrapper'>
              <button
                disabled={isLoading}
                type='submit'
                className='w-32 h-12 ml-auto rounded border border-[#E60A54]  bg-[#E60A54] font-medium  transition text-white '
              >
                {isLoading ? (
                  <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
                ) : (
                  'LOGIN'
                )}
              </button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  )
}

export default Login
