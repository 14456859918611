import React, { Fragment } from 'react'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import CustomTitle from '../../../../components/Custom/CustomTitle'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomInput from '../../../../custom/CustomInput'
import CustomSelect from '../../../../custom/CustomSelect'
import CustomToast from '../../../../components/Custom/CustomToast'

const provinceList = [
  { value: 'Alberta', label: 'Alberta' },
  { value: 'British Columbia', label: 'British Columbia' },
  { value: 'Manitoba', label: 'Manitoba' },
  { value: 'New Brunswick', label: 'New Brunswick' },
  { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
  { value: 'Nova Scotia', label: 'Nova Scotia' },
  { value: 'Northwest Territories', label: 'Northwest Territories' },
  { value: 'Nunavut', label: 'Nunavut' },
  { value: 'Ontario', label: 'Ontario' },
  { value: 'Prince Edward Island', label: 'Prince Edward Island' },
  { value: 'Quebec', label: 'Quebec' },
  { value: 'Saskatchewan', label: 'Saskatchewan' },
  { value: 'Yukon', label: 'Yukon' },
]

const validationSchema = Yup.object().shape({
  state_code: Yup.string().required('Province is required.'),
  tax_name: Yup.string().trim().required('Tax Name is required.'),
  rate: Yup.number()
    .required('Rate is required.')
    .min(1, 'Rate must be a number between 1 and 100.')
    .max(100, 'Rate must be a number between 1 and 100.'),
  priority: Yup.string().trim().required('Priority is required.'),
})

const Add = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosInterceptorInstance.post('/tax/create', {
        ...values,
      })

      if (response.data.success) {
        CustomToast({
          message: response.data.message || 'Tax created successfully!',
          type: 'success',
        })
        resetForm()
        navigate('/setting/tax/all')
      }
    } catch (error) {
      handleRequestError(error)
    }
  }
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      state_code: '',
      tax_name: '',
      rate: '',
      priority: '',
    },
    validateOnBlur: false,
  })

  const { isSubmitting } = formik
  return (
    <Fragment>
      <CustomTitle backBtn customTitle='Add' />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10'>
            <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
              <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='tax_name'
                    label='Tax Name'
                    placeholder='Tax Name'
                    component={CustomInput}
                    required={true}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                  />
                </div>
                <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='rate'
                    type='number'
                    label='Rate (%)'
                    placeholder='Rate (%)'
                    component={CustomInput}
                    required={true}
                    inputMode='numeric'
                    onKeyPress={(e) => {
                      const keyCode = e.keyCode || e.which
                      const keyValue = String.fromCharCode(keyCode)
                      const isValid = /\d|\./.test(keyValue)
                      if (!isValid) {
                        e.preventDefault()
                      }
                    }}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='state_code'
                    label='Province'
                    placeholder='Select Province'
                    component={CustomSelect}
                    options={provinceList}
                    required={true}
                    value={provinceList.find(
                      (option) => option.value === formik.values.state_code,
                    )}
                  />
                </div>
                <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='priority'
                    type='number'
                    label='Priority'
                    placeholder='Priority'
                    component={CustomInput}
                    required={true}
                    pattern='[0-9]*'
                    title='Please enter only numbers'
                    inputMode='numeric'
                    onKeyPress={(e) => {
                      const keyCode = e.keyCode || e.which
                      const keyValue = String.fromCharCode(keyCode)
                      if (/\D/.test(keyValue)) {
                        e.preventDefault()
                      }
                    }}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                  />
                </div>
              </div>

              <button
                type='submit'
                className='btn btn-primary-ico w-20 ml-auto'
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </Fragment>
  )
}

export default Add
