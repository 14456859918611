import React, { Fragment, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import DataTable from 'react-data-table-component'
import CustomDeleteUpdateModal from '../../../../components/Custom/CustomDeleteUpdateModal'
import CustomToast from '../../../../components/Custom/CustomToast'

import { Icons } from '../../../../components/Icons'

const { FiTrash, FiChevronDown, FaRegEye } = Icons

const View = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const [updateuserModel, setupdateuserModel] = useState('')
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const [deleteVehicleModel, setdeleteVehicleModel] = useState('')
  const [userDetails, setUserDetails] = useState(null)

  const [isloading, setisloading] = useState(false)

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/user/detail/${id}`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // const deleteModalhandler = (userid) => {
  //     setdeleteuserModel(userid)
  // }
  const deleteVehicleModalhandler = (vehicleid) => {
    setdeleteVehicleModel(vehicleid)
  }
  // const editModalhandler = async (userid) => {
  //     setupdateuserModel(userid)
  // }
  const submitedithandler = async () => {
    setisloading(true)
    try {
      const response = await axiosInterceptorInstance.put(
        `/user/update/${updateuserModel}`,
      )

      fetchData()
      CustomToast({
        message:
          response.data.message ||
          'User verification status updated successfully',
        type: 'success',
      })
      setisloading(false)
      setIsLoading(false)
      setupdateuserModel(false)
    } catch (error) {
      setisloading(false)
      setIsLoading(false)
      setupdateuserModel(false)
      handleRequestError(error)
    }
  }

  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/user/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'User deleted successfully',
        type: 'success',
      })
      setisloading(false)
      setIsLoading(false)
      setdeleteuserModel('')
      navigate('/user/all')
    } catch (error) {
      setisloading(false)
      setIsLoading(false)
      setdeleteuserModel('')
      handleRequestError(error)
    }
  }
  const submitvehicledeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `vehicle/seller/vehicle-details/delete/${deleteVehicleModel}`,
      )
      CustomToast({
        message:
          response.data.message || 'Vehicle details deleted successfully',
        type: 'success',
      })
      setisloading(false)
      setIsLoading(false)
      setdeleteVehicleModel('')
      fetchData()
    } catch (error) {
      setisloading(false)
      setIsLoading(false)
      setdeleteVehicleModel('')
      handleRequestError(error)
    }
  }

  const closeModal = () => {
    setupdateuserModel('')
    setdeleteuserModel('')
  }
  const closeVehicleModal = () => {
    setdeleteVehicleModel('')
  }
  const columns = [
    {
      name: 'VIN',
      sortable: true,
      selector: ({ vin_number }) => vin_number,
    },

    {
      name: 'Make',
      sortable: true,
      selector: ({ make }) => make,
    },
    {
      name: 'Model',
      sortable: true,
      selector: ({ model }) => model,
    },
    {
      name: 'Year',
      sortable: true,
      selector: ({ year }) => year,
    },
    {
      name: 'Status',
      sortable: true,
      selector: ({ status }) => status,
    },
    {
      name: 'Actions',
      sortable: false,
      cell: (row) => (
        <Fragment>
          <NavLink
            to={`/vehicle/view/${row._id}`}
            className='px-1 py-1 duration-300 ease-linear delay-150 rounded-full'
          >
            <FaRegEye />
          </NavLink>
          <button
            onClick={() => deleteVehicleModalhandler(row._id)}
            className='px-1 py-1 duration-300 ease-linear delay-150 rounded-full'
          >
            <FiTrash />
          </button>
        </Fragment>
      ),
    },
  ]

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <div className='flex flex-col w-full gap-10 '>
        <div className='flex flex-col items-start justify-start w-full gap-2 p-0 m-0'>
          <div className='w-full'>
            <CustomTitle backBtn />
          </div>
        </div>
        <div className='flex flex-wrap items-start justify-start w-full gap-10 p-0 m-0'>
          <div className='w-full lg:flex-[0_1_calc(45%_-_20px)]'>
            <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 mb-10 border shadow-2xl gap-y-8 border-gray/10 rounded-xl bg-primary/5 last:mb-0'>
              <div>
                {userDetails?.profile_picture ? (
                  <img
                    src={userDetails?.profile_picture}
                    className='object-cover w-20 h-20 p-0 border rounded-full border-gray/10'
                    alt='img'
                    onError={(e) => userBrokenImages(e)}
                  />
                ) : (
                  <div className='flex items-center justify-center object-cover w-20 h-20 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                    {userDetails?.first_name?.substring(0, 2)?.toUpperCase()}
                  </div>
                )}
              </div>

              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-semibold uppercase subHeading-4'>
                      Personal Information
                    </h4>
                  </div>
                  <div className='content'>
                    <p>
                      Joined{' '}
                      {userDetails?.created_at
                        ? new Date(userDetails.created_at).toLocaleString(
                            'en-US',
                            {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            },
                          )
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>first name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>{userDetails?.first_name}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>last name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>{userDetails?.last_name}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>Email</h5>
                  </div>
                  <div className='content'>
                    <p>
                      <a
                        href={`mailto:${userDetails?.email}`}
                        className='text-primary hover:text-black'
                      >
                        {userDetails?.email}
                      </a>
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>Phone Number</h5>
                  </div>
                  <div className='content'>
                    <a
                      href={`tel:${userDetails?.phone}`}
                      className='text-primary hover:text-black'
                    >
                      {userDetails?.phone}
                    </a>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>Role</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>{userDetails?.role}</p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>
                      Auction Access number
                    </h5>
                  </div>
                  <div className='content'>
                    <p>{userDetails?.auction_access_number}</p>
                  </div>
                </div>
              </div>
            </div>

            {userDetails?.role === 'buyer' ? (
              ''
            ) : (
              <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 border gap-y-8 border-gray/10 rounded-xl '>
                <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                  <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                    <div className='title title-primary'>
                      <h4 className='font-semibold uppercase subHeading-4'>
                        Financial information
                      </h4>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Bank Name</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.seller_bank_name}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Account Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {
                          userDetails?.user_financial_details
                            ?.seller_account_number
                        }
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Account Holder Name
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {
                          userDetails?.user_financial_details
                            ?.sellr_account_holder_name
                        }
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Routing Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {
                          userDetails?.user_financial_details
                            ?.seller_routing_number
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='w-full lg:flex-[0_1_calc(55%_-_20px)]'>
            <div className='flex flex-wrap items-center justify-center w-full p-0 px-8 pt-5 pb-8 m-0 bg-white border gap-y-5 border-gray/10 rounded-xl'>
              <div>
                {userDetails?.user_dealer_details?.dealer_logo ? (
                  <img
                    src={userDetails?.user_dealer_details?.dealer_logo}
                    className='object-cover w-20 h-20 p-0 border rounded-full border-gray/10'
                    alt='img'
                    onError={(e) => userBrokenImages(e)}
                  />
                ) : (
                  <div className='flex items-center justify-center object-cover w-20 h-20 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                    {userDetails?.user_dealer_details?.name
                      ?.substring(0, 2)
                      ?.toUpperCase()}
                  </div>
                )}
              </div>

              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-semibold uppercase subHeading-4'>
                      Dealer information
                    </h4>
                  </div>
                  <div className='content'>
                    <p>
                      Joined{' '}
                      {userDetails?.user_dealer_details?.created_at
                        ? new Date(userDetails.created_at).toLocaleString(
                            'en-US',
                            {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            },
                          )
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>
                      Dealership Name/Business Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.name
                        ? userDetails?.user_dealer_details?.name
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>
                      Primary Contact Person
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.contact_person
                        ? userDetails?.user_dealer_details?.contact_person
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>
                      Contact Number
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.phone ? (
                        <a
                          href={`tel:${userDetails.user_dealer_details.phone}`}
                          className='text-primary hover:text-black'
                        >
                          {userDetails.user_dealer_details.phone}
                        </a>
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>
                      Contact Email
                    </h5>
                  </div>
                  <div className='content'>
                    <p>
                      {userDetails?.user_dealer_details?.email ? (
                        <a
                          href={`mailto:${userDetails.user_dealer_details.email}`}
                          className='text-primary hover:text-black'
                        >
                          {userDetails.user_dealer_details.email}
                        </a>
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>Website URL</h5>
                  </div>
                  <div className='content'>
                    <p>
                      {userDetails?.user_dealer_details?.website ? (
                        <a
                          href={userDetails.user_dealer_details.website}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-primary hover:text-black'
                        >
                          {userDetails.user_dealer_details.website}
                        </a>
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                </div>

                {userDetails?.role === 'buyer' && (
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                    <div className='mb-2 capitalize title title-gray'>
                      <h5 className='font-semibold subHeading-5'>
                        Intrested Province
                      </h5>
                    </div>
                    <ul className='flex flex-wrap items-start justify-start w-full gap-2 p-0 m-0 list-none'>
                      {userDetails?.user_dealer_details?.intrested_province?.map(
                        (province) => {
                          return (
                            <li
                              key={province}
                              className='px-2 py-1 text-xs text-primary bg-primary/10'
                            >
                              {province}
                            </li>
                          )
                        },
                      )}
                    </ul>
                  </div>
                )}

                <div className='w-full'>
                  <div className='mb-5 capitalize title title-gray'>
                    <h5 className='font-semibold subHeading-5'>Address</h5>
                  </div>
                  <ul className='flex flex-col items-start justify-start w-full gap-10 p-0 m-0'>
                    {userDetails?.user_dealer_details?.address.map(
                      (address, index) => {
                        const fullAddress = `${
                          address.street ? address.street + ', ' : ''
                        }${address.city ? address.city + ', ' : ''}${
                          address.province ? address.province + ', ' : ''
                        }${address.postcode ? address.postcode : ''}`

                        return (
                          <li className='w-full' key={index}>
                            <div className='flex flex-wrap items-start justify-center w-full gap-8 p-0 m-0'>
                              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                                <div className='mb-2 capitalize title title-gray'>
                                  <h5 className='font-semibold subHeading-5'>
                                    Address {index + 1}:
                                  </h5>
                                </div>
                                <div className='capitalize content'>
                                  <p>{fullAddress ? fullAddress : '-'}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      },
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userDetails?.role === 'seller' && (
        <div className='w-full mt-2'>
          <div className='mb-5 capitalize title title-gray'>
            <h5 className='font-semibold subHeading-5'>Listed Vehicles</h5>
          </div>
          <DataTable
            columns={columns}
            data={userDetails?.listed_vehicles}
            sortIcon={<FiChevronDown size={10} />}
            persistTableHead={true}
            noDataComponent={
              <Fragment>
                <div className='m-5'>
                  There are no vehicle listings available.
                </div>
              </Fragment>
            }
            progressPending={isLoading}
            progressComponent={
              <div className='flex items-center justify-center py-5'>
                <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
              </div>
            }
            pointerOnHover
          />
        </div>
      )}
      <div className='w-full mt-10'>
        <Link
          to={`/user/edit/${id}`}
          className='ml-auto btn btn-primary-ico w-fit'
        >
          Edit
        </Link>
      </div>

      {updateuserModel && (
        <CustomDeleteUpdateModal
          closeModal={closeModal}
          isloading={isloading}
          onSubmit={submitedithandler}
          message={
            userDetails._id === updateuserModel && userDetails.is_admin_verified
              ? 'Do you want to disapprove this user?'
              : 'Do you want to approve this user?'
          }
        />
      )}
      {deleteuserModel && (
        <CustomDeleteUpdateModal
          closeModal={closeModal}
          isloading={isloading}
          onSubmit={submitdeletehandler}
          message={'Are you sure you want to delete this user?'}
        />
      )}

      {deleteVehicleModel && (
        <CustomDeleteUpdateModal
          closeModal={closeVehicleModal}
          isloading={isloading}
          onSubmit={submitvehicledeletehandler}
          message={'Are you sure you want to delete this vehicle?'}
        />
      )}
    </CustomLoadingSection>
  )
}

export default View
