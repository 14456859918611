import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import io from 'socket.io-client'

import { Link, NavLink, useNavigate } from 'react-router-dom'
import { handleRequestError } from '../../utils/axios/handleRequestError'

import configManager from '../../config/configManager'

import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import InvitationNotificationYear from '../../utils/notificationYearFormatter/InvitationNotificationYear'

import { Icons } from '../Icons'

const { MdChat, FiBell, FaCar, FaUser, IoDocumentTextSharp } = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const DropdownNotification = () => {
  const navigate = useNavigate()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  const [isNotificatinLoading, setIsNotificatinLoading] = useState(true) //eslint-disable-line no-unused-vars
  const [notificationList, setNotificationList] = useState([])

  const fetchNotificationList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/notification/latest/list`,
      )

      if (response.data.success) {
        setNotificationList(response.data.notifications)
        setIsNotificatinLoading(false)
      }
    } catch (error) {
      setIsNotificatinLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchNotificationList()
  }, [])

  useEffect(() => {
    socket.on('notification_received', () => {
      fetchNotificationList()
    })
  }, [])

  useEffect(() => {
    socket.on('admin_all_notifications_read', () => {
      fetchNotificationList()
    })
  }, [])

  useEffect(() => {
    socket.on('admin_single_notifications_read', () => {
      fetchNotificationList()
    })
  }, [])

  const makeAllNotificationAsRead = async () => {
    try {
      await axiosInterceptorInstance.post(`/notification/all/read`)
    } catch (error) {
      handleRequestError(error)
    }
  }

  const handleOpenNotification = async (item) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/read/${item._id}`,
      )

      if (response.data.success) {
        if (item.type === 'user') {
          navigate(`/user/view/${item?.user_id?._id}`)
        }
        if (item.type === 'vehicle') {
          navigate(`/vehicle/view/${item?.vehicle_id?._id}`)
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  return (
    <div>
      <div
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className='p-2 text-white hover:text-primary flex items-center justify-center rounded-full cursor-pointer'
      >
        <FiBell size={20} />
      </div>
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute z-[1] right-1 md:right-6 w-[440px] h-[400px] flex flex-col rounded-xl bg-white shadow-lg mt-2 py-5 px-5 overflow-x-hidden notifications ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <div className='bg-white pb-3 dropDown-title sticky top-0'>
          <div className='flex flex-wrap items-end justify-between w-full px-5 pt-10 pb-5 border-b border-gray/10'>
            <h5 className='text-lg font-medium text-gray'>Notifications</h5>
            {notificationList.length > 0 && (
              <span
                className='text-sm font-normal cursor-pointer text-primary'
                onClick={() => {
                  makeAllNotificationAsRead()
                }}
              >
                Mark all as read
              </span>
            )}
          </div>
        </div>
        <ul className='flex gap-y-4 flex-col dropDown-list h-[calc(100dvh_-_200px)] overflow-y-auto w-full px-5 py-5 overflow-x-hidden'>
          {notificationList.length > 0 ? (
            notificationList.map((item, key) => (
              <li
                key={key}
                className={`${
                  item.is_admin_read === false ? 'bg-primary bg-opacity-10' : ''
                } rounded-lg px-4 cursor-pointer transition duration-300 ease-in-out transform hover:scale-[1.02] `}
                onClick={() => handleOpenNotification(item)}
              >
                <Link className='flex flex-wrap gap-2 py-5 group/list' to='#'>
                  <div className='flex flex-wrap items-center justify-center m-0 p-0 w-[calc(100%_-_85px)] gap-3'>
                    <div className='w-8 h-8 flex flex-col items-center justify-center bg-primary rounded-full text-white text-lg'>
                      {item.type === 'vehicle' && <FaCar />}
                      {item.type === 'user' && <FaUser />}
                      {item.type === 'document' && <IoDocumentTextSharp />}
                      {item.type === 'chat' && <MdChat />}
                      {item.type === 'vehicle_invite' && <FaUser />}
                    </div>
                    <div className='flex flex-col items-start justify-start m-0 p-0 gap-1 w-[calc(100%_-_44px)]'>
                      <div className='text-base text-gray font-medium'>
                        <InvitationNotificationYear message={item.message} />
                      </div>
                      <p className='text-xs text-gray/70 font-light'>
                        {moment(item?.created_at).format('lll')}
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            ))
          ) : (
            <p className='text-center text-gray'>No notifications found</p>
          )}
        </ul>
        <div className='flex flex-wrap items-center justify-center w-full pt-3 m-0 border-t border-gray/10'>
          <div className='text-primary text-base font-semibold duration-300 delay-150 ease-linear cursor-pointer'>
            <NavLink to={'/notifications'}>Show all notification </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropdownNotification
