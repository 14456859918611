import React from 'react'
import PropTypes from 'prop-types'

import { Icons } from '../../Icons'

const { FiSearch } = Icons

const CustomSearch = ({
  value,
  onChange,
  onClick,
  disabled = false,
  placeholder = 'Search...',
}) => {
  return (
    <div className='flex items-center relative'>
      <input
        type='text'
        placeholder={placeholder}
        className='px-3 py-1 text-sm border rounded-full border-gray/20 focus:outline-none text-gray font-light h-[46px] w-[300px] shadow'
        value={value}
        onChange={onChange}
      />
      <button
        className={`text-primarytransition duration-300 flex items-center absolute right-3 ${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        <FiSearch className='' size={20} />
      </button>
    </div>
  )
}

CustomSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default CustomSearch
