import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// custom components
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'
import CustomTitle from '../../../components/Custom/CustomTitle'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

const View = () => {
  const { id } = useParams()

  const [templateDetails, setTemplateDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchTemplateDetails = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/template/details/${id}`,
      )
      setIsLoading(false)
      if (response.data.success) {
        setTemplateDetails(response?.data?.templateDetails)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchTemplateDetails()
  }, [id])
  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn customTitle='View' />
      <div className='flex flex-wrap items-start justify-start w-full gap-10 p-0 m-0'>
        <div className='w-full lg:flex-[0_1_calc(45%_-_20px)]'>
          <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 mb-10 border shadow-2xl gap-y-8 border-gray/10 rounded-xl bg-primary/5 last:mb-0'>
            <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
              <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                <div className='title title-primary'>
                  <h4 className='font-semibold uppercase subHeading-4'>
                    Template Details
                  </h4>
                </div>
                <div className=''>
                  <p>
                    Created At{' '}
                    {templateDetails?.created_at
                      ? new Date(templateDetails.created_at).toLocaleString(
                          'en-US',
                          {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          },
                        )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                <div className='capitalize title title-gray'>
                  <h5 className='font-semibold subHeading-5'>From Name</h5>
                </div>
                <div className='capitalize'>
                  <p>{templateDetails?.from_name}</p>
                </div>
              </div>

              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                <div className='mb-2 capitalize title title-gray'>
                  <h5 className='font-semibold subHeading-5'>From Email</h5>
                </div>
                <div className=''>
                  <p>
                    <a
                      href={`mailto:${templateDetails?.from_email}`}
                      className='text-primary hover:text-black'
                    >
                      {templateDetails?.from_email}
                    </a>
                  </p>
                </div>
              </div>

              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                <div className='mb-2 capitalize title title-gray'>
                  <h5 className='font-semibold subHeading-5'>Subject</h5>
                </div>
                <div className='capitalize'>
                  <p>{templateDetails?.subject}</p>
                </div>
              </div>

              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                <div className='mb-2 capitalize title title-gray'>
                  <h5 className='font-semibold subHeading-5'>Type</h5>
                </div>

                <div className='capitalize'>
                  <p>{templateDetails?.type}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right side for HTML rendering */}
        <div className='w-full lg:flex-[0_1_calc(55%_-_20px)]'>
          <div className='p-8 m-0 bg-white border gap-y-5 border-gray/10 rounded-xl'>
            <div className='title title-primary'>
              <h4 className='font-semibold uppercase subHeading-4'>Preview</h4>
            </div>
            <iframe
              title='template-preview'
              className='p-8 m-0 bg-white border gap-y-5 border-gray/10 rounded-xl'
              style={{ width: '100%', height: '600px' }}
              srcDoc={templateDetails?.html}
            />
          </div>
        </div>
      </div>
    </CustomLoadingSection>
  )
}

export default View
