import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'

// custom components
import CustomInput from '../../../../custom/CustomInput'
import CustomModal from '../../../../components/Custom/CustomModal'
import CustomTitle from '../../../../components/Custom/CustomTitle'

// redux
import { setVehicleDetais } from '../../../../redux/vehicle/vehicleSlice'

// context
import { useStepContext } from '../../../../context/useStepContext'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'
// assets
import carImage from '../../../../assets/car/car.png'
import whereismyvin from '../../../../assets/car/Where_Is_My_VIN_Image.png'

import { Icons } from '../../../../components/Icons'

const {
  TiMediaPlayOutline,
  IoInformationCircleOutline,
  MdArrowRightAlt,
  MdOutlineClose,
} = Icons

const VinDecoder = () => {
  const navigate = useNavigate()

  const data = useSelector((store) => store.vehicle)

  const [isLoading, setIsLoading] = useState(false)
  const [vehicleImg, setVehicleImg] = useState(null)
  const [FetchedData, setFetchedData] = useState(false)
  const [isVINDecoderError, setIsVINDecoderError] = useState(false)
  const [whereIsMyVinModel, setWhereIsMyVinModel] = useState(false)

  const schema = Yup.object().shape({
    vin_number: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed')
      .required('VIN is required.')
      .max(17, 'Must be exactly 17 digits')
      .min(17, 'Must be exactly 17 digits')
      .test('trim', 'Please provide valide vin number', (value) => {
        return value.trim().length === value.length
      }),
  })

  const dispatch = useDispatch()

  const { handleStepClick } = useStepContext()

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/vin/details/${values.vin_number}`,
      )

      if (response.data.VehicleDetails.success) {
        setIsLoading(false)
        dispatch(setVehicleDetais(response.data.VehicleDetails))
        setFetchedData(true)
        resetForm()
      }
    } catch (error) {
      setIsLoading(false)
      setIsVINDecoderError(true)
      setFetchedData(false)
      resetForm()
      handleRequestError(error)
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      vin_number: '',
    },
  })

  const handleCloseModal = () => {
    setWhereIsMyVinModel(false)
  }
  const OpenModalss = () => {
    setWhereIsMyVinModel(true)
  }

  const [VehicleListing, setVehicleListing] = useState(null)

  const listeNewVehicleHandler = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/vin/check/${data.vin}`,
      )
      if (response.data.success) {
        if (!response.data.isListedVin) {
          handleStepClick(1)
          setIsLoading(false)
          navigate(`/vehicle/add`)
        }
      }
    } catch (error) {
      setIsLoading(false)
      setVehicleListing(
        'Oops, it looks like that vehicle is listed by someone else. Please double check your VIN or contact customer service at Revvit.',
      )
      setFetchedData(null)
      setFetchedData(null)
      setIsVINDecoderError(null)
    }
  }

  useEffect(() => {
    data?.photos?.length > 0
      ? setVehicleImg(data?.photos[0]?.url)
      : setVehicleImg(
          'https://res.cloudinary.com/dsxplvawm/image/upload/v1711617517/placeholder-car-image_osswep.png',
        )
  }, [data])

  return (
    <Fragment>
      <CustomTitle backBtn customTitle='VIN Decoder' />
      <div
        className='w-full flex flex-wrap items-start gap-y-10 bg-no-repeat bg-50% bg-right-bottom overflow-y-auto max-large:py-10'
        style={{ backgroundImage: `url(${carImage})` }}
      >
        <div className='large:max-w-[calc(100%_-_20%)] mx-auto flex flex-wrap items-center justify-center w-full large:pt-10 gap-y-10'>
          <div className='large:basis-1/3 w-full py-0'>
            <h2 className='text-4xl text-primary font-semibold mb-5 last:mb-0'>
              Sell My Vehicle
            </h2>
            <p className='text-sm leading-6 text-gray mb-3 last:mb-0'>
              Get a secure sale when you sell your vehicle online with Revvit.
              Let us help with the steps and paperwork while you control the
              price and sale.
            </p>
            <ul className='flex flex-col gap-2 items-start justify-start mb-3 last:mb-0'>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Save time on state required forms
              </li>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Verified community of buyers
              </li>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Sell completely online
              </li>
            </ul>
          </div>
          <div className='large:basis-1/2 w-full'>
            <div className='h-full large:max-h-[calc(100%_-_30%)] p-7 w-full large:max-w-[calc(100%_-_15%)] mx-auto flex flex-col items-center justify-center bg-gray/5 backdrop-blur rounded-lg border border-gray/10 shadow-lg'>
              {VehicleListing && (
                <div className='flex flex-wrap justify-between content-between w-full gap-5 h-full'>
                  <h3 className='text-xl font-bold text-gray w-full pb-5 border-b border-gray/10 text-center'>
                    VIN Is Registered
                  </h3>
                  <div className='flex flex-wrap justify-start items-start gap-5 w-full'>
                    <p className='mt-4 text-gray text-base'>
                      <span className='text-primary'>Oops,</span> it looks like
                      that vehicle is listed by someone else.
                    </p>
                    <p className='mt-4 text-gray text-base'>
                      Please double check your VIN or contact customer service
                      to <span className='text-primary'> Revvit</span>.
                    </p>
                    <div className='mt-4 ml-auto'>
                      <div
                        onClick={() => {
                          setIsVINDecoderError(false)
                          setVehicleListing(null)
                          setFetchedData(null)
                        }}
                        className='btn btn-primary w-fit cursor-pointer'
                      >
                        Try again
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {FetchedData ? (
                <Fragment>
                  <div className='flex flex-wrap justify-between content-between w-full gap-5 h-full'>
                    <h3 className='text-xl font-bold text-gray w-full pb-5 border-b border-gray/10 text-center'>
                      {data.vin}
                    </h3>
                    <div className='flex flex-wrap justify-center items-start gap-5 w-full'>
                      <div className='w-full'>
                        <div className='relative block w-full'>
                          <img
                            className='max-w-[250px] h-[180px] block rounded-md mx-auto object-cover'
                            width={250}
                            height={250}
                            onError={(e) => vehicleBrokenImage(e)}
                            src={vehicleImg}
                            alt='vehicle img'
                          />
                        </div>
                      </div>

                      <div className='w-full text-center'>
                        <h1 className='mb-2 text-gray text-lg font-bold'>
                          {data.MMYT.year} {data.MMYT.make} {data.MMYT.model}{' '}
                          {data.MMYT.trim}
                        </h1>
                        <p className='text-sm text-gray/70 mb-8'>
                          Not my vehicle?&nbsp;
                          <span
                            onClick={() => {
                              setFetchedData(false)
                            }}
                            className='inline btn-primary-link cursor-pointer'
                          >
                            Try again
                          </span>
                        </p>
                        <button
                          disabled={isLoading}
                          type='button'
                          onClick={listeNewVehicleHandler}
                          className='btn flex items-center justify-center gap-2 bg-primary text-white btn-full'
                        >
                          {isLoading ? (
                            <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                          ) : (
                            <Fragment>
                              Continue
                              <MdArrowRightAlt className='text-2xl' />
                            </Fragment>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : isVINDecoderError ? (
                <div className='flex flex-wrap justify-between content-between w-full gap-5 h-full'>
                  <h3 className='text-xl font-bold text-gray w-full pb-5 border-b border-gray/10 text-center'>
                    We Couldn&apos;t Find That VIN
                  </h3>
                  <div className='flex flex-wrap justify-center items-start gap-5 w-full'>
                    <p className='mt-4 text-gray text-sm'>
                      Chances are it&#39;s not in our system. Please contact
                      customer service to{' '}
                      <span className='text-primary'> Revvit</span>.
                    </p>
                    <div className='mt-4 ml-auto'>
                      <div
                        onClick={() => setIsVINDecoderError(false)}
                        className='btn btn-primary w-fit cursor-pointer'
                      >
                        Try again
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                !VehicleListing && (
                  <FormikProvider value={formik}>
                    <div className='title text-center mb-5 last:mb-0'>
                      <h1 className='heading-2 text-gray mb-1'>
                        <span className=''> VIN</span> Decoder
                      </h1>
                      <p className='text-sm text-gray'>
                        Please enter your VIN to search your vehicle.
                      </p>
                    </div>
                    <form
                      onSubmit={formik.handleSubmit}
                      className='flex flex-col gap-4 w-full'
                    >
                      <div className='form-field-wrapper'>
                        <Field
                          name='vin_number'
                          placeholder='Enter VIN'
                          component={CustomInput}
                          className='form-field-white'
                          maxLength='17'
                        />
                      </div>
                      <div className='text-left mb-5'>
                        <p
                          onClick={OpenModalss}
                          className='inline btn-gray-link cursor-pointer '
                        >
                          <span className='flex items-center gap-1 text-sm text-gray/50'>
                            <IoInformationCircleOutline className='text-lg' />
                            Where is my VIN?
                          </span>
                        </p>
                      </div>
                      <button
                        disabled={isLoading}
                        type='submit'
                        className='btn btn-primary btn-full'
                      >
                        {isLoading ? (
                          <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                        ) : (
                          'Look Up My vehicle'
                        )}
                      </button>
                    </form>
                  </FormikProvider>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {whereIsMyVinModel && (
        <CustomModal
          handleCloseModal={handleCloseModal}
          closeModal={whereIsMyVinModel}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={handleCloseModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap items-center justify-center gap-10'>
              <div className='flex-auto'>
                <div className='title title-gray'>
                  <h5 className='heading-4 font-bold text-gray mb-5 border-b border-gray/10 pb-3'>
                    Where Can I find My VIN?
                  </h5>
                  <div>
                    <h5 className='subHeading-4 font-semibold text-gray mb-2'>
                      What is a VIN?
                    </h5>
                    <p className='text-sm text-gray'>
                      A Vehicle Identification Number (VIN) is a unique number
                      for your vehicle. It is 17 characters long and will look
                      like this:
                    </p>
                  </div>

                  <h5 className='heading-4 text-gray/50 my-8'>
                    JTEZT17RX58088469
                  </h5>

                  <div className=''>
                    <h5 className='subHeading-4 font-semibold text-gray mb-2'>
                      Where is it located?
                    </h5>
                    <p className='text-sm text-gray'>
                      You can find your VIN on the vehicle itself and/or your
                      vehicle&#39;s documentation
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex-auto'>
                <img
                  src={whereismyvin}
                  className='max-w-full h-auto mx-auto block'
                  alt='img'
                  onError={(e) => vehicleBrokenImage(e)}
                />
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </Fragment>
  )
}

export default VinDecoder
