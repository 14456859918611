import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomInput from '../../../custom/CustomInput'
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomToast from '../../../components/Custom/CustomToast'

const validationSchema = Yup.object().shape({
  from_name: Yup.string().trim().required('From Name is required.'),
  from_email: Yup.string()
    .trim()
    .email('Invalid email address.')
    .required('From Email is required.'),
  type: Yup.string().trim().required('Type is required.'),
  html: Yup.string().trim().required('HTML content is required.'),
  subject: Yup.string().trim().required('Subject is required.'),
})

const Add = () => {
  const navigate = useNavigate()
  const [previewHTML, setPreviewHTML] = useState('')
  const [iframeHeight, setIframeHeight] = useState('auto')

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosInterceptorInstance.post('/template/create', {
        ...values,
      })

      if (response.data.success) {
        CustomToast({
          message:
            response.data.message || 'Template has been created successfully!',
          type: 'success',
        })
        resetForm()
        navigate('/setting/template/all')
      }
    } catch (error) {
      handleRequestError(error)
    }
  }
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      from_name: '',
      from_email: '',
      subject: '',
      type: '',
      html: '',
    },
    validateOnBlur: false,
  })

  const { isSubmitting } = formik

  // Dynamically adjust iframe height when previewHTML changes
  useEffect(() => {
    const iframe = document.getElementById('html-preview-iframe')
    if (iframe) {
      // Wait for the content to be loaded
      iframe.onload = () => {
        const newHeight = iframe.contentWindow.document.body.scrollHeight + 'px'
        setIframeHeight(newHeight)
      }
    }
  }, [previewHTML])

  return (
    <Fragment>
      <CustomTitle backBtn customTitle='Add' />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10'>
            <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
              <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='from_name'
                    label='From Name'
                    placeholder='From Name'
                    component={CustomInput}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    required={true}
                  />
                </div>
                <div className='max-lg:w-full lg:flex-auto'>
                  <Field
                    name='from_email'
                    label='From Email'
                    placeholder='From Email'
                    component={CustomInput}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    required={true}
                  />
                </div>
                <div className='w-full'>
                  <Field
                    name='subject'
                    label='Subject'
                    placeholder='Subject'
                    component={CustomInput}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    required={true}
                  />
                </div>

                <div className='max-lg:w-full lg:flex-auto'>
                  <Field
                    name='type'
                    label='Type'
                    placeholder='Type'
                    component={CustomInput}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    required={true}
                  />
                </div>

                <div className='w-full'>
                  <h5 className='flex items-center gap-1 text-base font-semibold text-gray'>
                    Email Content
                    <span className='text-danger'>*</span>
                  </h5>
                </div>
                <Field
                  component='textarea'
                  rows='16'
                  className='form-textarea'
                  name='html'
                  label='html'
                  placeholder='Add your html here to preview below.'
                  onChange={(e) => {
                    formik.handleChange(e)
                    setPreviewHTML(e.target.value)
                  }}
                />
                <ErrorMessage
                  name='html'
                  component='div'
                  className='text-xs font-normal text-danger mt-1 ml-1'
                />

                <div className='w-full'>
                  <h5 className='flex items-center gap-1 text-base font-semibold text-gray'>
                    Preview
                  </h5>
                </div>
                <iframe
                  title='html-preview'
                  id='html-preview-iframe'
                  className={`w-full ${
                    previewHTML
                      ? 'border border-gray border-opacity-10 rounded-md'
                      : ''
                  }`}
                  style={{ height: iframeHeight }}
                  srcDoc={previewHTML}
                />
              </div>

              <button
                type='submit'
                className='btn btn-primary-ico w-20 ml-auto'
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </Fragment>
  )
}

export default Add
