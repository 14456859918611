import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// redux
import { signOut } from '../../redux/user/userSlice'

// utils
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import { removeToken } from '../../utils/cookies/cookies'

import { Icons } from '../Icons'
import { handleRequestError } from '../../utils/axios/handleRequestError'

const { FaRegUserCircle, CiUser, AiOutlineLogout } = Icons

const DropdownUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const trigger = useRef(null)
  const dropdown = useRef(null)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  const handleLogout = async () => {
    try {
      await axiosInterceptorInstance.get('/auth/logout')
      dispatch(signOut())

      removeToken()

      navigate('/login')
    } catch (error) {
      handleRequestError(error)
    }
  }
  return (
    <div className='relative'>
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className=''
        to='#'
      >
        <span className='h-8 w-8 flex justify-center items-center rounded-full text-black bg-grey-200 hover:text-white hover:bg-primary text-xl'>
          <FaRegUserCircle />
        </span>
      </Link>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 flex w-60 flex-col mt-2 rounded-md border border-gray border-opacity-10 bg-white shadow-lg overflow-hidden userDropDown ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <ul className='flex flex-col dropDown-list'>
          <li>
            <Link
              to='/admin/profile'
              className='flex items-center gap-2 text-sm font-normal duration-300 ease-in-out py-3 px-5 hover:text-white hover:bg-primary'
            >
              <CiUser className='text-xl' />
              My Profile
            </Link>
          </li>
          <li>
            <Link
              to='#'
              className='flex items-center gap-2 text-sm font-normal duration-300 ease-in-out py-3 px-5 hover:text-white hover:bg-primary'
              onClick={handleLogout}
            >
              <AiOutlineLogout className='text-xl' />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DropdownUser
