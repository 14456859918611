import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component'
import { NavLink, useNavigate } from 'react-router-dom'

// custom components
import CustomNoDataComponent from '../../../components/Custom/CustomNoDataComponent'
import CustomDeleteUpdateModal from '../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../CustomToolTip'
import { useStepContext } from '../../../context/useStepContext'

import PropTypes from 'prop-types'

import { Icons } from '../../Icons'

const { FiChevronDown, FiTrash, FaRegEdit, FaRegEye } = Icons

const CustomVehicleTable = ({
  loading,
  columns,
  vehicleList,
  searchText,
  // totalPages,
  // currentPage,
  pageSize,
  searchLoading,
  deleteuserModel,
  handlePageChange,
  handlePerPageChange,
  handleRowClick,
  deleteModalhandler,
  submitdeletehandler,
  isloading,
  closeModal,
  noSearchResultMessage,
  noUserMessage,
  status,
  totalRecords,
}) => {
  const navigate = useNavigate()
  const { handleEditStepClick } = useStepContext()
  const handleEditClick = (userId, vehicleId) => {
    navigate(`/vehicle/edit/${userId}/${vehicleId}`)
    handleEditStepClick(1)
  }

  const data = vehicleList?.map((data) => {
    return {
      ...data,

      action: (
        <div className='flex flex-wrap gap-2 mt-1' role='group'>
          {(status === 'all' || status === 'draft' || status === 'parking') && (
            <CustomToolTip label='Edit'>
              <button
                type='button'
                // to={`/vehicle/edit/${data?.user?._id}/${data._id}`}
                onClick={() => handleEditClick(data?.user?._id, data._id)}
                className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
              >
                <FaRegEdit />
              </button>
            </CustomToolTip>
          )}
          <CustomToolTip label='View'>
            <NavLink
              to={`/vehicle/view/${data._id}`}
              className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
            >
              <FaRegEye />
            </NavLink>
          </CustomToolTip>
          {(status === 'all' || status === 'draft' || status === 'parking') && (
            <CustomToolTip label='Delete'>
              <button
                onClick={() => deleteModalhandler(data?._id)}
                className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
              >
                <FiTrash />
              </button>
            </CustomToolTip>
          )}
        </div>
      ),
    }
  })

  const noDataComponent = (
    <CustomNoDataComponent
      columns={columns}
      noSearchResultMessage={noSearchResultMessage}
      noUserMessage={noUserMessage}
      loading={loading}
      searchText={searchText}
      List={vehicleList}
      data={data}
    />
  )

  return (
    <Fragment>
      {loading ? (
        <div className='w-full flex justify-center items-center'>
          <div className='h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent'></div>
        </div>
      ) : (
        <Fragment>
          <DataTable
            data={data}
            columns={columns}
            sortIcon={<FiChevronDown size={10} />}
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 60, 80, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerPageChange}
            highlightOnHover
            noDataComponent={noDataComponent}
            pointerOnHover
            onRowClicked={handleRowClick}
            noHeader
            persistTableHead={true}
            progressPending={searchLoading}
            progressComponent={
              <div className='flex items-center justify-center py-5'>
                <div className='w-16 h-16 border-4 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
              </div>
            }
          />
        </Fragment>
      )}

      {deleteuserModel && (
        <CustomDeleteUpdateModal
          closeModal={closeModal}
          isloading={isloading}
          onSubmit={submitdeletehandler}
          message={'Are you sure you want to delete this vehicle?'}
        />
      )}
    </Fragment>
  )
}

CustomVehicleTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  vehicleList: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  deleteuserModel: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePerPageChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  deleteModalhandler: PropTypes.func.isRequired,
  submitdeletehandler: PropTypes.func.isRequired,
  isloading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  noSearchResultMessage: PropTypes.string.isRequired,
  noUserMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  totalRecords: PropTypes.number.isRequired,
}

export default CustomVehicleTable
