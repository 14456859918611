import React from 'react'
import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'

const CustomToolTip = ({ children, label }) => {
  return (
    <Tooltip
      title={label}
      position='top'
      trigger='mouseenter'
      className='max-sm:w-full'
    >
      {children}
    </Tooltip>
  )
}

CustomToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
}

export default CustomToolTip
