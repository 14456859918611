import React, { Fragment, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomInput from '../../../custom/CustomInput'
import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomToast from '../../../components/Custom/CustomToast'
import CustomDeleteUpdateModal from '../../../components/Custom/CustomDeleteUpdateModal'
import CustomToolTip from '../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../components/Icons'

const { FaRegEye, FiTrash } = Icons

const validationSchema = Yup.object().shape({
  from_name: Yup.string().trim().required('From Name is required.'),
  from_email: Yup.string()
    .trim()
    .email('Invalid email address.')
    .required('From Email is required.'),
  type: Yup.string().trim().required('Type is required.'),
  html: Yup.string().trim().required('HTML content is required.'),
  subject: Yup.string().trim().required('Subject is required.'),
})

const Edit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [previewHTML, setPreviewHTML] = useState('')

  const [isloading, setisloading] = useState(false)
  const [deleteuserModel, setdeleteuserModel] = useState('')
  const [iframeHeight, setIframeHeight] = useState('auto')

  const fetchTemplateDetails = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/template/details/${id}`,
      )
      setIsLoading(false)
      if (response.data.success) {
        formik.setValues({
          ...formik.values,
          ...response?.data?.templateDetails,
        })
        setPreviewHTML(response?.data?.templateDetails?.html)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  useEffect(() => {
    fetchTemplateDetails()
  }, [id])

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.put(
        `/template/edit/${id}`,
        { ...values },
      )
      setIsLoading(false)
      if (response.data.success) {
        CustomToast({
          message:
            response.data.message ||
            'Template details has been updated successfully!',
          type: 'success',
        })
        resetForm()
        navigate('/setting/template/all')
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      from_name: '',
      from_email: '',
      subject: '',
      type: '',
      html: '',
    },
    validateOnBlur: false,
  })

  const submitdeletehandler = async () => {
    setisloading(true)

    try {
      const response = await axiosInterceptorInstance.delete(
        `/template/delete/${deleteuserModel}`,
      )
      CustomToast({
        message: response.data.message || 'Announcement deleted successfully',
        type: 'success',
      })

      setisloading(false)

      setdeleteuserModel('')
      navigate('/setting/template/all')
    } catch (error) {
      setisloading(false)

      setdeleteuserModel('')
      handleRequestError(error)
    }
  }

  const deleteModalhandler = (vehicleid) => {
    setdeleteuserModel(vehicleid)
  }

  const closeModal = () => {
    setdeleteuserModel('')
  }

  const handleHTMLChange = (e) => {
    const htmlContent = e.target.value
    setPreviewHTML(htmlContent)
    formik.setFieldValue('html', htmlContent)
  }

  useEffect(() => {
    const iframe = document.getElementById('html-preview-iframe')
    if (iframe) {
      // Wait for the content to be loaded
      iframe.onload = () => {
        const newHeight = iframe.contentWindow.document.body.scrollHeight + 'px'
        setIframeHeight(newHeight)
      }
    }
  }, [previewHTML])

  return (
    <Fragment>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='Edit' />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className='w-full'>
            <div className='flex flex-col w-full gap-10'>
              <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
                <div className='flex justify-end'>
                  <CustomToolTip label='PreView Template'>
                    <NavLink
                      to={`/setting/template/view/${id}`}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                    >
                      <FaRegEye size={30} />
                    </NavLink>
                  </CustomToolTip>
                  <CustomToolTip label='Delete Template'>
                    <button
                      type='button'
                      onClick={() => deleteModalhandler(id)}
                      className='px-1 py-1 w-7 h-7 duration-300 flex items-center justify-center ease-linear delay-150 rounded-full'
                    >
                      <FiTrash />
                    </button>
                  </CustomToolTip>
                </div>
                <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                  <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                    <Field
                      name='from_name'
                      label='From Name'
                      placeholder='From Name'
                      component={CustomInput}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                      required={true}
                    />
                  </div>
                  <div className='max-lg:w-full lg:flex-auto'>
                    <Field
                      name='from_email'
                      label='From Email'
                      placeholder='From Email'
                      component={CustomInput}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                      required={true}
                    />
                  </div>
                  <div className='w-full'>
                    <Field
                      name='subject'
                      label='Subject'
                      placeholder='Subject'
                      component={CustomInput}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                      required={true}
                    />
                  </div>

                  <div className='max-lg:w-full lg:flex-auto'>
                    <Field
                      name='type'
                      label='Type'
                      placeholder='Type'
                      component={CustomInput}
                      className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                      required={true}
                    />
                  </div>

                  <div className='w-full'>
                    <h5 className='flex items-center gap-1 text-base font-semibold text-gray'>
                      Email Content
                      <span className='text-danger'>*</span>
                    </h5>
                  </div>
                  <Field
                    component='textarea'
                    rows='16'
                    className='form-textarea'
                    name='html'
                    label='html'
                    placeholder='Add your html here to preview below.'
                    onChange={handleHTMLChange}
                    value={formik.values.html}
                  />
                  <ErrorMessage
                    name='html'
                    component='div'
                    className='text-xs font-normal text-danger mt-1 ml-1'
                  />
                  <div className='w-full'>
                    <h5 className='flex items-center gap-1 text-base font-semibold text-gray'>
                      Preview
                    </h5>
                  </div>
                  <iframe
                    title='html-preview'
                    id='html-preview-iframe'
                    className={`w-full ${
                      previewHTML
                        ? 'border border-gray border-opacity-10 rounded-md'
                        : ''
                    }`}
                    style={{ height: iframeHeight }}
                    srcDoc={previewHTML}
                  />
                </div>
                <button
                  type='submit'
                  className='btn btn-primary-ico w-20 ml-auto'
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>
            {deleteuserModel && (
              <CustomDeleteUpdateModal
                closeModal={closeModal}
                isloading={isloading}
                onSubmit={submitdeletehandler}
                message={'Are you sure you want to delete this announcement?'}
              />
            )}
          </form>
        </FormikProvider>
      </CustomLoadingSection>
    </Fragment>
  )
}

export default Edit
