import React, { Fragment } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'

// utils
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

// custom components
import CustomInput from '../../../../custom/CustomInput'
import CustomRadioInput from '../../../../custom/CustomRadioInput'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToast from '../../../../components/Custom/CustomToast'

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is required.'),
  message: Yup.string().trim().required('Message is required.'),
  target_role: Yup.string()
    .trim()
    .required('Target Role is required.')
    .oneOf(['seller', 'buyer', 'all'], 'Invalid Target Role'),
  status: Yup.string()
    .trim()
    .required('Status is required.')
    .oneOf(['active', 'inactive'], 'Invalid Status'),
})

const roles = [
  { value: 'seller', label: 'Seller' },
  { value: 'buyer', label: 'Buyer' },
  { value: 'all', label: 'All' },
]
const status = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
]
const Add = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosInterceptorInstance.post(
        '/announcements/create',
        { ...values },
      )

      if (response.data.success) {
        CustomToast({
          message:
            response.data.message || 'Announcement created successfully!',
          type: 'success',
        })
        resetForm()
        navigate('/announcement/all')
      }
    } catch (error) {
      handleRequestError(error)
    }
  }
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      title: '',
      message: '',
      target_role: 'all',
      status: 'active',
    },
    validateOnBlur: false,
  })

  const { isSubmitting } = formik

  return (
    <Fragment>
      <CustomTitle backBtn customTitle='Add' />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10'>
            <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
              <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
                <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='title'
                    label='Title'
                    placeholder='Title'
                    component={CustomInput}
                    className='bg-white w-full text-gray text-sm border border-gray border-opacity-10 rounded-md py-3 px-5 outline-0'
                    required={true}
                  />
                </div>
                <div className='max-lg:w-full lg:flex-auto'>
                  <Field
                    name='target_role'
                    label='Target Role'
                    component={CustomRadioInput}
                    options={roles}
                    className='custom-radio'
                    required={true}
                  />
                </div>
                <div className='max-lg:w-full lg:flex-auto'>
                  <Field
                    name='status'
                    label='Status'
                    component={CustomRadioInput}
                    options={status}
                    className='custom-radio'
                    required={true}
                  />
                </div>
                <div className='w-full'>
                  <div className='w-full'>
                    <h5 className='flex items-center gap-1 text-base font-semibold text-gray mb-3'>
                      Message
                      <span className='text-danger'>*</span>
                    </h5>
                  </div>
                  <Field
                    component='textarea'
                    rows='4'
                    className='form-textarea'
                    name='message'
                    label='message'
                  />
                  <ErrorMessage
                    name='message'
                    component='div'
                    className='text-xs font-normal text-danger mt-1 ml-1'
                  />
                </div>
              </div>

              <button
                type='submit'
                className='btn btn-primary-ico w-20 ml-auto'
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent' />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </Fragment>
  )
}

export default Add
