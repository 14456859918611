import React, { useState } from 'react'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'

import 'react-tippy/dist/tippy.css'

import { Icons } from '../components/Icons'

const { FaRegEye, FaRegEyeSlash } = Icons

const CustomInput = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  required,
  placeholder,
  isDisabled,
  uppercase,
  passwords,
  showPassword,
  setShowPassword,
  passwordshint,
  ...props
}) => {
  const [submitted, setSubmitted] = useState(false)

  const isInvalid =
    (touched[field.name] && errors[field.name]) ||
    (submitted && errors[field.name])

  const handleChange = (e) => {
    const { name, value } = e.target
    let processedValue = value

    if (uppercase) {
      processedValue = value.toUpperCase()
    }
    if (name === 'phone') {
      processedValue = processedValue.replace(/\D/g, '')

      let formattedPhoneNumber = ''

      if (processedValue.length > 0) {
        formattedPhoneNumber += '(' + processedValue.substring(0, 3)
      }

      if (processedValue.length >= 4) {
        formattedPhoneNumber += ') ' + processedValue.substring(3, 6)
      }

      if (processedValue.length >= 7) {
        formattedPhoneNumber += '-' + processedValue.substring(6, 10)
      }

      processedValue = formattedPhoneNumber
    }
    setFieldValue(name, processedValue)
  }

  const handleFocus = () => {
    setFieldTouched(field.name, true, false)
  }
  return (
    <div className='w-full'>
      <h5 className='flex items-center gap-1 text-base font-semibold text-gray mb-3'>
        {label}
        {required && <span className='text-danger'>*</span>}
      </h5>

      <div className='relative flex gap-4 items-center '>
        <input
          {...field}
          {...props}
          placeholder={placeholder}
          aria-invalid={isInvalid ? 'true' : 'false'}
          onBlur={() => setSubmitted(true)}
          onFocus={passwordshint && handleFocus}
          disabled={isDisabled}
          onChange={(e) => handleChange(e)}
        />

        {passwords && (
          <div
            className='absolute right-4'
            onClick={() => {
              setShowPassword((pre) => !pre)
            }}
          >
            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </div>
        )}
      </div>
      {
        <ErrorMessage
          name={field.name}
          component='div'
          className='text-xs font-normal text-danger mt-1 ml-1'
        />
      }
    </div>
  )
}

CustomInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,

  isDisabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  passwords: PropTypes.bool,
  showPassword: PropTypes.bool,
  setShowPassword: PropTypes.func,
  passwordshint: PropTypes.bool,
}

export default CustomInput
