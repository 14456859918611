import React from 'react'

// custom components
import CustomToolTip from '../../../components/Custom/CustomToolTip'

export const columns = [
  {
    name: 'Name',
    sortable: true,
    selector: ({ first_name, last_name }) =>
      first_name && last_name ? (
        <CustomToolTip label={`${first_name} ${last_name}`}>
          <span title={`${first_name} ${last_name}`} className='capitalize'>
            {`${first_name} ${last_name}`}
          </span>
        </CustomToolTip>
      ) : first_name || last_name ? (
        <CustomToolTip label={first_name || last_name}>
          <span title={first_name || last_name} className='capitalize'>
            {first_name || last_name}
          </span>
        </CustomToolTip>
      ) : null,
  },
  {
    name: 'Email',
    sortable: true,
    selector: ({ email }) => email,
    cell: ({ email }) => (
      <CustomToolTip label={email}>
        <span title={email}>{email}</span>
      </CustomToolTip>
    ),
  },
  {
    name: 'Phone Number',
    sortable: true,
    selector: ({ phone }) => phone,
  },
  {
    name: 'Role',
    sortable: true,
    selector: ({ role }) => role,
  },

  {
    name: 'Registered At',
    sortable: true,
    selector: ({ created_at }) => created_at,
    format: ({ created_at }) => (
      <CustomToolTip
        label={new Date(created_at).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      >
        <span>
          {new Date(created_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </span>
      </CustomToolTip>
    ),
  },
  {
    name: 'Action',
    selector: ({ approve }) => approve,
  },
  {
    name: '',
    sortable: false,
    selector: ({ action }) => action,
  },
]
