import React from 'react'
import Home from '../pages/Home'

import AllVehicle from '../pages/Vehicle/All'
import DraftVehicle from '../pages/Vehicle/Draft'
import LiveVehicle from '../pages/Vehicle/Live'
import ScheduledVehicle from '../pages/Vehicle/Scheduled'
import PendingVehicle from '../pages/Vehicle/Pending'
import ClosedVehicle from '../pages/Vehicle/closed'
import AwardedVehicle from '../pages/Vehicle/Awarded'
import ParkingVehicle from '../pages/Vehicle/Parking'

import VinDecoder from '../pages/Vehicle/All/actions/VinDecoder'

import ViewVehicle from '../pages/Vehicle/All/actions/View'
import AddVehicle from '../pages/Vehicle/All/actions/Add'
import EditVehicle from '../pages/Vehicle/All/actions/Edit'

import AllUser from '../pages/User/All'
import PendingUser from '../pages/User/Pending'
import SellerUser from '../pages/User/sellers'
import BuyerUser from '../pages/User/buyers'
import RoleChangeRequests from '../pages/User/RoleSwitchRequests'

import View from '../pages/User/All/actions/View'
import AddUser from '../pages/User/All/actions/Add'
import EditUser from '../pages/User/All/actions/Edit'

import TimeFrame from '../pages/Setting/TimeFrame'

import AllAnnouncement from '../pages/Announcement/All'
import EditAnnouncement from '../pages/Announcement/All/actions/Edit'
import ViewAnnouncement from '../pages/Announcement/All/actions/View'
import AddAnnouncement from '../pages/Announcement/All/actions/Add'

import AllTax from '../pages/Tax/All'
import AddTax from '../pages/Tax/All/actions/Add'
import EditTax from '../pages/Tax/All/actions/Edit'
import ViewTax from '../pages/Tax/All/actions/View'

import AdminProfile from '../pages/Auth/Profile'

import Notifications from '../pages/Notification'

import AllTemplate from '../pages/Template'
import AddTemplate from '../pages/Template/actions/Add'
import EditTemplate from '../pages/Template/actions/Edit'
import ViewTemplate from '../pages/Template/actions/View'

const AllRoutes = [
  { path: '/', element: <Home /> },
  { path: '/vehicle/all', element: <AllVehicle /> },
  { path: '/vehicle/parking', element: <ParkingVehicle /> },
  { path: '/vehicle/draft', element: <DraftVehicle /> },
  { path: '/vehicle/live', element: <LiveVehicle /> },
  { path: '/vehicle/schedule', element: <ScheduledVehicle /> },
  { path: '/vehicle/pending', element: <PendingVehicle /> },
  { path: '/vehicle/closed', element: <ClosedVehicle /> },
  { path: '/vehicle/awarded', element: <AwardedVehicle /> },

  { path: '/vin/decoder', element: <VinDecoder /> },
  { path: '/vehicle/add', element: <AddVehicle /> },
  { path: '/vehicle/edit/:userId/:id', element: <EditVehicle /> },
  { path: '/vehicle/view/:id', element: <ViewVehicle /> },

  { path: '/user/all', element: <AllUser /> },
  { path: '/user/pending', element: <PendingUser /> },
  { path: '/user/seller', element: <SellerUser /> },
  { path: '/user/buyer', element: <BuyerUser /> },
  { path: '/user/role-change-requests', element: <RoleChangeRequests /> },

  { path: '/user/add', element: <AddUser /> },
  { path: '/user/edit/:id', element: <EditUser /> },
  { path: '/user/view/:id', element: <View /> },

  { path: '/setting/offer-time', element: <TimeFrame /> },

  { path: '/announcement/all', element: <AllAnnouncement /> },
  { path: '/announcement/edit/:id', element: <EditAnnouncement /> },
  { path: '/announcement/view/:id', element: <ViewAnnouncement /> },
  { path: '/announcement/create', element: <AddAnnouncement /> },

  { path: '/setting/tax/all', element: <AllTax /> },
  { path: '/setting/tax/edit/:id', element: <EditTax /> },
  { path: '/setting/tax/view/:id', element: <ViewTax /> },
  { path: '/setting/tax/create', element: <AddTax /> },

  { path: '/admin/profile', element: <AdminProfile /> },
  { path: '/notifications', element: <Notifications /> },

  { path: '/setting/template/all', element: <AllTemplate /> },
  { path: '/setting/template/edit/:id', element: <EditTemplate /> },
  { path: '/setting/template/view/:id', element: <ViewTemplate /> },
  { path: '/setting/template/create', element: <AddTemplate /> },
]

export default AllRoutes
