import Cookies from 'js-cookie'

// token
export const getToken = () => {
  return Cookies.get('Admintoken')
}
export const setToken = (token) => {
  Cookies.set('Admintoken', token)
}

export const removeToken = () => {
  Cookies.remove('Admintoken')
}
