import React, { useState } from 'react'

// custom components
import CustomTitle from '../../../../components/Custom/CustomTitle'

// personal details
import PersonalDetails from '../../../../views/User/Add/PersonalDetails'

// financial details
import FinancialDetails from '../../../../views/User/Add/FinancialDetails'

// dealer details
import DealerDetails from '../../../../views/User/Add/DealerDetails'

const Add = () => {
  const [step, setStep] = useState(1)
  return (
    <div>
      <CustomTitle backBtn />
      <div className='mt-4'>
        <div className='add-tabs md:flex items-center lg:justify-start'>
          <div
            // onClick={() => setStep(1)}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 1 && 'active'
            }`}
          >
            Pesonal Details
          </div>
          <div
            // onClick={() => setStep(2)}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 2 && 'active'
            }`}
          >
            Dealer Details
          </div>
          <div
            // onClick={() => setStep(3)}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 3 && 'active'
            }`}
          >
            Financial Details
          </div>
        </div>
        <div className='border border-gray border-opacity-10 rounded-xl rounded-tl-none max-md:rounded-tr-none p-5 md:px-8 md:py-10'>
          {step === 1 && <PersonalDetails setStep={setStep} />}
          {step === 2 && <DealerDetails setStep={setStep} />}
          {step === 3 && <FinancialDetails setStep={setStep} />}
        </div>
      </div>
    </div>
  )
}

export default Add
