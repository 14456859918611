import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'

import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// utils
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

// custom components
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomInput from '../../../../custom/CustomInput'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { Icons } from '../../../../components/Icons'

const { MdArrowRightAlt, MdOutlineCheck } = Icons

const validationSchema = Yup.object().shape({
  bank_name: Yup.string().required('Bank name is required'),
  account_number: Yup.string().required('Account number is required'),
  institution_number: Yup.string().required('Institution number is required'),
  transit_number: Yup.string().required('Transit number is required'),
})

const BuyerFinancialDetails = ({ setStep }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [userDetails, setUserDetails] = useState()
  const [userFinancialDetails, setUserFinancialDetails] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(`/user/detail/${id}`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        setUserFinancialDetails(
          response.data.userdetails?.user_financial_details,
        )
        formik.setValues({
          ...formik.values,
          ...response.data.userdetails?.user_financial_details,
        })
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSubmit = async (values, { resetForm }) => {
    if (userDetails?._id && userDetails.is_financial_details_completed) {
      try {
        const response = await axiosInterceptorInstance.put(
          `/user/financial/update/buyer/${userFinancialDetails._id}`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          resetForm()
          navigate('/user/all')
        }
      } catch (error) {
        handleRequestError(error)
      }
    } else {
      try {
        const response = await axiosInterceptorInstance.post(
          `/user/financial/create/buyer/${userDetails._id}`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          navigate('/user/all')
        }
      } catch (error) {
        handleRequestError(error)
      }
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      bank_name: 'bank name',
      account_number: '000123456789',
      institution_number: '000',
      transit_number: '11000',
      swift_code: '',
    },
  })

  const { isSubmitting } = formik
  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10 '>
            <div>
              <div className='title title-primary text-left mb-3'>
                <h2 className='heading-2'>Financial Information</h2>
              </div>
              <div className='content text-left'>
                <p>
                  Please provide your bank name, branch name, transit number and
                  institution number.
                </p>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-x-5 gap-y-8'>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='bank_name'
                  label='Bank Name'
                  placeholder='Bank Name'
                  component={CustomInput}
                  required={true}
                  className='form-field'
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='number'
                  name='account_number'
                  label='Account Number'
                  placeholder='Account Number'
                  component={CustomInput}
                  required={true}
                  className='form-field'
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='transit_number'
                  label='Transit Number'
                  placeholder='Transit Nnumber'
                  component={CustomInput}
                  required={true}
                  className='form-field'
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='number'
                  name='institution_number'
                  label='Institution Number'
                  placeholder='Institution Number'
                  component={CustomInput}
                  required={true}
                  className='form-field'
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='number'
                  name='swift_code'
                  label='Swift Code'
                  placeholder='Swift Code'
                  component={CustomInput}
                  className='form-field'
                />
              </div>
            </div>
            <div className='flex items-center justify-center w-full gap-5 mt-10 flex-wrap md:items-center md:justify-between'>
              <button
                type='button'
                className='prev next-prev-button xs:flex-auto xs:order-1 order-2'
                onClick={() => setStep(2)}
              >
                <MdArrowRightAlt /> Back
              </button>
              <button
                type='submit'
                className='btn btn-primary-ico ml-auto w-full xs:w-auto xs:order-2 order-1'
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className='h-5 w-5 mx-auto animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
                ) : (
                  <Fragment>
                    <span>Submit</span>
                    <MdOutlineCheck />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

BuyerFinancialDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default BuyerFinancialDetails
