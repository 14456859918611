import React from 'react'

// custom components
import CustomToolTip from '../../components/Custom/CustomToolTip'

export const columns = [
  {
    name: 'From Name',
    sortable: true,
    selector: ({ from_name }) => from_name,
  },
  {
    name: 'From Email',
    sortable: true,
    selector: ({ from_email }) => from_email,
    cell: ({ from_email }) => (
      <CustomToolTip label={from_email}>
        <span title={from_email}>{from_email}</span>
      </CustomToolTip>
    ),
  },

  {
    name: 'Type',
    sortable: true,
    selector: ({ type }) => type,
    cell: ({ type }) => (
      <CustomToolTip label={type}>
        <span title={type}>{type}</span>
      </CustomToolTip>
    ),
  },
  {
    name: 'Subject',
    sortable: true,
    selector: ({ subject }) => subject,
  },

  {
    name: 'Created At',
    sortable: true,
    selector: ({ created_at }) => created_at,
    format: ({ created_at }) => (
      <CustomToolTip
        label={new Date(created_at).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      >
        <span>
          {new Date(created_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </span>
      </CustomToolTip>
    ),
  },
  {
    name: '',
    sortable: false,
    selector: ({ action }) => action,
  },
]
