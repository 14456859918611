import { initializeApp } from 'firebase/app'
import { getToken, getMessaging, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAunUOL1GUysVeFhSl_HkBaTGRB5Iol4v0',
  authDomain: 'revvit-458e3.firebaseapp.com',
  projectId: 'revvit-458e3',
  storageBucket: 'revvit-458e3.appspot.com',
  messagingSenderId: '1060935585982',
  appId: '1:1060935585982:web:f32647fd08798eddafceef',
  measurementId: 'G-WP27HVF4TE',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      '/firebase-push-notification-scope',
    )
    if (serviceWorker) return serviceWorker
    return window.navigator.serviceWorker.register(
      '/firebase-messaging-sw.js',
      {
        scope: '/firebase-push-notification-scope',
      },
    )
  }
  throw new Error('The browser doesn`t support service worker.')
}

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        'BMHNmZ22kF9Tccg3Qczbpq1eAD_1jPdullfK-i2_4k_N4nj5cpMTkl6Ao-qyGBAx3Llm27XjyDSl3Z8gs0isXBs',
      serviceWorkerRegistration,
    }),
  )

onMessage(messaging, (payload) => {
  const notificationTitle = payload.notification.title
  const notificationBody = {
    body: payload.notification.body,
  }

  if (Notification.permission === 'granted' && 'Notification' in window) {
    new Notification(notificationTitle, notificationBody)
  } else {
    console.log('Notification permission has not been granted.')
  }
})
