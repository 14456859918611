import React, { useEffect, useState } from 'react'

// utils
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// custom components
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'
import CustomTitle from '../../../components/Custom/CustomTitle'

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [userDetails, setUserDetails] = useState(null)

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/auth/user/details`)

      if (response.data.success) {
        setUserDetails(response.data.adminDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle backBtn customTitle='Profile' />
        <section className='acc_user'>
          <div className='flex flex-col w-full gap-10 border border-gray border-opacity-10 rounded-xl p-5 md:px-8 md:py-10'>
            <div className='flex flex-wrap items-start justify-start w-full m-0 p-0 gap-5'>
              <div className='w-full'>
                <h5 className='text-gray text-sm font-500 mb-2'>Username</h5>
                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {userDetails?.username}
                </div>
              </div>

              <div className='w-full'>
                <h5 className='text-gray text-sm font-500 mb-2'>Email</h5>
                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {userDetails?.email || ''}
                </div>
              </div>
              <div className='w-full'>
                <h5 className='text-gray text-sm font-500 mb-2'>
                  Registered At
                </h5>
                <div className='bg-transparent w-full text-gray/70 text-sm border-b border-gray/10 pb-2 pr-3 outline-0 capitalize'>
                  {userDetails?.created_at
                    ? new Date(userDetails.created_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : '-'}
                </div>
              </div>
            </div>
          </div>
        </section>
      </CustomLoadingSection>
    </div>
  )
}

export default Profile
